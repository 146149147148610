import { apiConfig } from "../config/Config.js";
import { apiEndPoint } from "../constant/ApiConstant.js";
import { ApiService } from "../service/ApiService.js";
import UtilDateTime from "../util/UtilDateTime.js";
import { trimValue } from "../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
} from "../constant/StorageConstant.js";
import UserSessionController from "./UserSession/UserSessionController.js";

let AdminDashboardCtrlr = {

// ---------------------------------- Admin Dashboard Summary api -------------------------------------------------- //

    async getV1AdminDashboardSummary( startDateTime, endDateTime, filter) {
       
        var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);        
        var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);        
        var dashboardFields = { durationType: filter,};
    
        if (companyId.length > 0 && divisionId.length > 0) {

          var reqParams = {
            companyId       : companyId,
            groupId         : groupId,
            divisionId      : divisionId,            
            dashboardType   : "V1_ADMIN_DASHBOARD_SUMMARY",
            startDateTime   : startDateTime,
            endDateTime     : endDateTime,
            dashboardFields : JSON.stringify(dashboardFields),
          };
              
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
          );

        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed","code":"412", "data": []}';
        }
      },

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Admin Dashboard Summary api -------------------------------------------------- //

async getV1AdminDashboardPeriodicalSummary( startDateTime, endDateTime, filter) {
       
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);        
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);        
  var dashboardFields = { durationType: filter,};

  if (companyId.length > 0 && divisionId.length > 0) {

    var reqParams = {
      companyId       : companyId,
      groupId         : groupId,
      divisionId      : divisionId,            
      dashboardType   : "V1_ADMIN_DASHBOARD_PERIODICAL_SUMMARY",
      startDateTime   : startDateTime,
      endDateTime     : endDateTime,
      dashboardFields : JSON.stringify(dashboardFields),
    };
        
    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqParams
    );

  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed","code":"412", "data": []}';
  }
},

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Admin Dashbord User List table api -------------------------------------------------- //

async getAdminV1UserList (indexStart,sortValue,filter,searchText, startDateTime, endDateTime,userListType){
   
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);    
    var dashboardFields = {durationType : filter,searchType: "WILD_CARD_SEARCH",searchText:searchText,userListType:userListType,}

    if (companyId.length > 0) {

      var reqPayload = {
          companyId       : companyId,
          groupId         : groupId,
          divisionId      : divisionId,          
          sortBy          : "createdAt",
          sortOrder       : sortValue,
          offset          : indexStart,
          limit           : 25,
          dashboardType   : "V1_USER_LIST",
          dashboardFields : JSON.stringify(dashboardFields),
          startDateTime   : startDateTime, 
          endDateTime     : endDateTime,
      };

      return await ApiService.encryptCallGET(
          apiConfig.API_VERSION_1,
          apiEndPoint.DASHBOARD,
          1,
          reqPayload
      );

  } else {

      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

  },
  
  // --------------------------------------------------------------------------------------------- //


  // ---------------------------------- Admin Dashbord Schedule based user periodical summary api -------------------------------------------------- //

async getAdminV1MultiUserPeriodicalSummaryList (indexStart,sortValue,filter,searchText, startDateTime, endDateTime,userListType){
   
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);    
  var dashboardFields = {durationType : filter,searchType: "WILD_CARD_SEARCH",searchText:searchText,userListType:userListType,}

  if (companyId.length > 0) {

    var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,          
        sortBy          : "createdAt",
        sortOrder       : sortValue,
        offset          : indexStart,
        limit           : 25,
        dashboardType   : "V1_MULTI_USER_PERIODICAL_SUMMARY_LIST",
        dashboardFields : JSON.stringify(dashboardFields),
        startDateTime   : startDateTime, 
        endDateTime     : endDateTime,
    };

    return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
    );

} else {

    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
}

},

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Admin Dashbord Multi User Driving Score List api -------------------------------------------------- //

async getAdminV1MultiUserDrivingScoreList(
    filterDay,
    offset,
    pageSize,
    startDateTime,
    endDateTime,
) {
    
    var dashboardFields = {durationType : filterDay,}
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);    

    if (companyId.length > 0 && divisionId.length > 0) {

        var reqParams = {
            companyId       : companyId,
            groupId         : groupId,
            divisionId      : divisionId,      
            sortBy          : "createdAt",
            sortOrder       : "ASC",
            offset          : offset,
            limit           : pageSize,      
            dashboardType   : "V1_MULTI_USER_DRIVING_SCORE_LIST",           
            dashboardFields : JSON.stringify(dashboardFields),  
            startDateTime   : startDateTime,
            endDateTime     : endDateTime          
        };

        return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
        );

    } else {
        return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }

},

 // --------------------------------------------------------------------------------------------- //

 // ---------------------------------- Vehicle Crash List api -------------------------------------------------- //

async getAdminV1VehicleCrashList(
    indexStart,
    filter,
    deviceFilter,
    startDateTime,
    endDateTime,
    accidentTypeFilter,   
    pageSize
  ) {
  
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var reportFields    = {
                            durationType  : filter,
                            accidentType  : accidentTypeFilter,
                            deviceType    : deviceFilter,
                          };
  
    if (companyId.length > 0 && divisionId.length > 0) {
  
      var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,       
        offset          : indexStart,
        limit           : pageSize,     
        dashboardType   : "V1_VEHICLE_CRASH_LIST",
        startDateTime   : startDateTime,
        endDateTime     : endDateTime,
        dashboardFields : JSON.stringify(reportFields),
      };
  
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
  
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  
  },
  
  // --------------------------------------------------------------------------------------------- //

  // ---------------------------------- Vehicle Crash List api New Updated -------------------------------------------------- //

async getAdminV2VehicleCrashList(
  indexStart,
  filter,
  deviceFilter,
  startDateTime,
  endDateTime,
  accidentTypeFilter,   
  pageSize
) {

  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  var reportFields    = {
                          durationType  : filter,
                          accidentType  : accidentTypeFilter,
                          deviceType    : deviceFilter,
                        };

  if (companyId.length > 0 && divisionId.length > 0) {

    var reqPayload = {
      companyId       : companyId,
      groupId         : groupId,
      divisionId      : divisionId,       
      offset          : indexStart,
      limit           : pageSize,     
      dashboardType   : "V2_VEHICLE_CRASH_LIST",
      startDateTime   : startDateTime,
      endDateTime     : endDateTime,
      dashboardFields : JSON.stringify(reportFields),
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqPayload
    );

  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

},

// --------------------------------------------------------------------------------------------- //


async MySubscriptionAdmin() {
  var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

  if (companyId.length > 0 ) {
    var reqPayload = {
      companyId: companyId,
      divisionId: divisionId,
      groupId:groupId,      
      queryType:"QV1_SUBSCRIPTION_LIST"
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.QUERY,
      1,
      reqPayload
    );
  } else {
    return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
  }
},

async MySubscriptionAdmin() {
  var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

  if (companyId.length > 0 ) {
    var reqPayload = {
      companyId: companyId,
      divisionId: divisionId,
      groupId:groupId,      
      queryType:"QV1_SUBSCRIPTION_LIST"
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.QUERY,
      1,
      reqPayload
    );
  } else {
    return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
  }
},

async MySubscriptionAddUserPopupAdmin() {
  var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

  if (companyId.length > 0 ) {
    var reqPayload = {
      companyId: companyId,
      divisionId: divisionId,
      groupId:groupId,      
      dashboardType:"V1_SUBSCRIPTION_LIST"
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqPayload
    );
  } else {
    return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
  }
},


async getV1UserRideList(
  indexStart,
  filterDays,
  mode,   
  subFilterText,
  pageSize,
  startDateTime,
  endDateTime
) {
  var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID); 
  var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);

  var dashboardFields = {
      durationType: filterDays,    
    searchType: "WILD_CARD_SEARCH",
    searchText: subFilterText,
    deviceMode: mode,
    travelType: "RIDE_DRIVER",
  };

  if (companyId.length > 0 ) {

    var reqPayload = {
      companyId: companyId,
      groupId:groupId,      
      divisionId: divisionId,       
      sortBy: "createdAt",
      sortOrder: "ASC",
      offset: indexStart,
      limit: 25,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dashboardType: "V1_RIDE_LIST",
      dashboardFields: JSON.stringify(dashboardFields),
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqPayload
    );

  } else {

    return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';

  }
}, 

};

export default AdminDashboardCtrlr;
