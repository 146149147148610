import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const RidesKmHrsScoreCBar = ({aggregateData, totalDays}) => {
  // console.log(aggregateData, "aggregateDataaggregateData");
  
    const floatedDistanceData = parseFloat(aggregateData.totalDistance) || 0;
    const floatedDurationData = aggregateData.totalDuration < 60 ?  parseFloat(aggregateData.totalDuration).toFixed(2) : (parseFloat(aggregateData.totalDuration) / 60).toFixed(2);
    const rideData = [
        { header: 'Total Days', icon: 'ad-2r-calendar-new.svg', value: totalDays, unit: '', alt:'Days' },
        { header: 'Total Users', icon: 'ad-2r-user-new.svg', value: aggregateData.totalUsers, unit: '', alt:'users'  },
        { header: 'Total Rides', icon: 'ad-2r-ridding-new.svg', value: aggregateData.drivingSummaryTotalRides, unit: '', alt:'Rides' },
        { header: 'Total Distance', icon: 'ad-2r-distance-new.svg', value: floatedDistanceData, unit: 'kms', alt:'Distance' },
        { header: 'Total Duration', icon: 'ad-2r-time-new.svg', value: floatedDurationData , unit: aggregateData.totalDuration < 60 ? 'Mins' : 'Hours', alt:'Duration'  },      
        { header: 'High Risk Alerts', icon: 'ad-2r-red-alerts.svg', value: aggregateData.totalHighRiskCountNew, unit: '', alt:'Red Alerts'  },  
       
      ];
  return (
    <div>
      <Row>
        {rideData.map((data, index) => (
          <Col style={{padding:0}} key={index} xl={2} lg={2} md={4} sm={4} xs={6}>
            <Card className={index != 5 && 'me-2'}>
              <CardHeader className="ps-2 pe-2 pb-1 pt-2 sub_heading_title_card border-0">
                {data.header}
              </CardHeader>
              <CardBody className="body_text border-0 ps-2 pe-2 py-0" style={{ height:'55px',display:"flex",alignItems:"center"}}>
               {data.icon && <img src={require(`../../../assets/images/adminDashboard/${data.icon}`)} alt={data.alt} height={30} width={30} />}
                <div className='sub_heading_title_card' style={{marginLeft:"10px"}}>{data.value} {data.unit}</div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default RidesKmHrsScoreCBar;
