import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";
import { Tooltip } from "@mui/material";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg"
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActType from "../../assets/images/superAdminIcons/actType.svg";
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";
import MDTotal from "../../assets/images/superAdminIcons/MDTotal.svg";
import MDVersion from "../../assets/images/superAdminIcons/MDVersion.svg";
import MDModel from "../../assets/images/superAdminIcons/MDModel.svg";
import MDRegDate from "../../assets/images/superAdminIcons/MDRegDate.svg";
import LDDeviceId from "../../assets/images/superAdminIcons/deviceId.svg";
import MSAppVersion from "../../assets/images/superAdminIcons/MSAppVersion.svg";
import MSLastSession from "../../assets/images/superAdminIcons/MSLastSession.svg";
import MSLastActivity from "../../assets/images/superAdminIcons/MSLastActivity.svg";
import steeringWheel from "../../assets/images/adminDashboard/steering-wheel.svg";
import UBIAnticipation from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import UBISelfConfidence from "../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg";
import UBIDrivingSkill from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg";
import UBIDrivingStyle from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg";
import UBIDrivingState from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg";
import UBIMobileCallUse from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
import OverSpeedIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import UBIStressStrain from "../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg";
import AccelerationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import CountImage from "../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import minsIcon from "../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import ScreenCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import SuperAdminDashboardCtrlr from "../../controller/SuperAdminCtrlr";
import noDeviceIcon from "../../assets/images/common/noDeviceIcon.svg";

export default function AppRideList() {
  const [showLoader, setShowLoader] = useState(false);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [exceptionFilter, setExceptionFilter] = useState("");
  const [exceptionFilter2, setExceptionFilter2] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("")
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);

  const [filters, setFilters] = useState({
    durationFilter: "ALL",
    startDateTime: "",
    endDateTime: "",
  });

  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 100,
    preLoginCrash: 20,
    preLoginOthers: 20,
    postLoginCrash: 20,
    postLoginOthers: 20,
    postLoginPermission: 10,
    postLoginPeriodical: 10,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
  });

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef();
  const [selectedValue, setSelectedValue] = useState("ALL_USER_LIST");

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
    // Do something with the selected option
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // getOverAllSummary(0);
  };

  const handleUserClick = (type) => {
    const selectedMapping = {
      ALL_LIST:"ALL_USER_LIST",
      ACTIVE_LIST: "ACTIVE_USER_LIST",      
      DELETED_LIST: "DELETED_USER_LIST",
    };
    setSelectedValue(selectedMapping[type]);    
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const getAppExceptionReport = async (indexStart) => {
    setShowLoader(true);
    try {
    var result = await Report.getUserAppExpReport(
      indexStart,
      filter,
      exceptionFilter,
      deviceFilter,
      startDateTime,
      endDateTime,
      pageSize,
      selectedOption
    );
    var tableData = [];
    
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            deviceType = "",
            uniqueId = "",
            versionNumber = "",
            appVersion = "",
            remote = "",
            userAgent = "",
            errorType = "",
            keyvalueTemp = "",
            batteryLevel = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];           
            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName));
            userIdTemp = userApp.userId || "";
            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            setTotalPageCount(Math.ceil(totalCounts/pageSize));
            setNextShow(indexStart + pageSize < totalCounts);

            if (userApp.deviceType === "ANDROID") {
              deviceType = "Android"
            } else if (userApp.deviceType === "ANDROID_SDK"){
              deviceType = "Android SDK"
            } else if (userApp.deviceType === "IOS") {
              deviceType = "iOS"
            } else if (userApp.deviceType === "IOS_SDK"){
              deviceType = "iOS SDK"
            } else  {
              deviceType = "N/A"
            }
            // deviceType = ValueFormat.parseAsString(
            //   ValueFormat.capitalizeFirstLetter(userApp.deviceType)
            // );
            uniqueId = ValueFormat.parseAsString(userApp.deviceUniqueId);
            versionNumber = ValueFormat.parseAsInt(userApp.deviceVersionNumber);
            appVersion = ValueFormat.parseAsFloat(userApp.appVersion);
            remote = ValueFormat.parseAsFloat(userApp.remoteAddress);
            userAgent = ValueFormat.parseAsString(userApp.userAgent);
            errorType = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userApp.errorType)
            );
            batteryLevel = ValueFormat.parseAsFloat(userApp.batteryLevel);
            keyvalueTemp =
              userApp.keyValues.map((val) =>
                ValueFormat.capitalizeFirstLetterAndFormat(val.key)
              ) || [];

            var appExceptiondetails = {
              firstName: firstName,
              deviceType: deviceType,
              uniqueId: uniqueId,
              versionNumber: versionNumber,
              appVersion: appVersion,
              remote: remote,
              userAgent: userAgent,
              errorType: errorType,
              batteryLevel: batteryLevel,
              keyvalueTemp: keyvalueTemp,
              userIdTemp: userIdTemp,
            };
            tableData.push(appExceptiondetails);
            setApiData(tableData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E1206" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard";
        //   }, 3500);
        // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        // }
        // console.log("Status failed");
      }
    } catch (errMes) {
      // console.log("Catch error:",errMes);
    } finally{
    setShowLoader(false);
    }
    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 1) {
      setPrevShow(true);
    }

  };

  const getRideListSA = async () => {
   
    var result = await SuperAdminDashboardCtrlr.getV1SuperAdminRideList();
    // console.log(result,"SuperAdmin")
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        
        
      } else {        
        // console.log("Status failed");
      }
    } catch (errMes) {}
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
      setPageNumber(1);
      if (indexStart == 0) setCount((prev) => prev + 1);
      else setIndexStart(0);
  }, [filter,exceptionFilter,deviceFilter,startDateTime,endDateTime,selectedOption]);

//   useEffect(() => {
//     if (count > 0) getAppExceptionReport(indexStart);
// }, [indexStart,count]);

  useEffect(() => {
    getRideListSA()
  }, []);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  function capitalizeLetter  (category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  }  


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
            <Row className="my-2 py-2 d-flex justify-content-start align-items-center mb-3 mt-3" >
            <Col xs={3} className="d-flex justify-content-start align-items-center text-black" style={{ height:"0px"}}>
              <h5 className="mb-0" style={{textWrap:"nowrap",}}>User Ride Detail</h5>
            </Col>
            {/* <Col xs={9} className="d-flex justify-content-end align-items-center text-black" style={{gap:10,}}>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center", }}>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == ""
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("")}
                  data-tooltip-id="alle"
                >
                  All
                  <ReactTooltip
                    id="alle"
                    place="top"
                    content="All"
                    style={{ background: "black" }}
                  />
                </button>

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "VALID"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("VALID")}
                  data-tooltip-id="Valid"
                >
                  Valid
                  <ReactTooltip
                    id="Valid"
                    place="top"
                    content="Valid"
                    style={{ background: "black" }}
                  />
                </button>

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "INVALID"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("INVALID")}
                  data-tooltip-id="Invalid"
                >
                  Invalid
                  <ReactTooltip
                    id="Invalid"
                    place="top"
                    content="Invalid"
                    style={{ background: "black" }}
                  />
                </button>

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "ABNORMAL"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("ABNORMAL")}
                  data-tooltip-id="Abnormal"
                >
                  Abnormal
                  <ReactTooltip
                    id="Abnormal"
                    place="top"
                    content="Abnormal"
                    style={{ background: "black" }}
                  />
                </button>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "DELETED"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("DELETED")}
                  data-tooltip-id="Deleted"
                >
                  Deleted
                  <ReactTooltip
                    id="Deleted"
                    place="top"
                    content="Deleted"
                    style={{ background: "black" }}
                  />
                </button>
              </div>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end" style={{ textAlign: "center", }}>
                <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center" }}>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == ""
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("")}
                    data-tooltip-id="alle"
                  >
                    All
                    <ReactTooltip
                      id="alle"
                      place="top"
                      content="All"
                      style={{ background: "black" }}
                    />
                  </button>          
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "DRIVER"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("DRIVER")}
                    data-tooltip-id="Driver"
                  >
                    Driver
                    <ReactTooltip
                      id="Driver"
                      place="top"
                      content="Driver"
                      style={{ background: "black" }}
                    />
                  </button>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "PASSENGER"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("PASSENGER")}
                    data-tooltip-id="PASSENGER"
                  >
                    Passenger
                    <ReactTooltip
                      id="PASSENGER"
                      place="top"
                      content="Passenger"
                      style={{ background: "black" }}
                    />
                  </button>             
                         
                  
                </div>
              </div>

              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end" style={{ textAlign: "center", }}>
                <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center" }}>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == ""
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("")}
                  data-tooltip-id="alle"
                >
                  All
                  <ReactTooltip
                    id="alle"
                    place="top"
                    content="All"
                    style={{ background: "black" }}
                  />
                </button>              

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "PERSONAL"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("PERSONAL")}
                  data-tooltip-id="Personal"
                >
                  Personal
                  <ReactTooltip
                    id="Personal"
                    place="top"
                    content="Personal"
                    style={{ background: "black" }}
                  />
                </button>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "WORK"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("WORK")}
                  data-tooltip-id="Work"
                >
                  Work
                  <ReactTooltip
                    id="Work"
                    place="top"
                    content="Work"
                    style={{ background: "black" }}
                  />
                </button>       
                         
                  
                </div>
              </div>

              <div className="pb-0 d-flex justify-content-end">
                <div className="ms-auto pe-0 ">
                  <div style={{ float: "right",  }}>
                  <Tooltip title="All">
                    <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "ALL" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "ALL",
                          });
                        }}
                      >
                        All                           
                      </button>
                      </Tooltip>
                      <Tooltip title="Current Day">
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_DAY" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_DAY",
                          });
                        }}
                      >
                        CD                           
                      </button>
                      </Tooltip>
                      {" "}
                      <Tooltip title="Current Week">
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_WEEK" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_WEEK",
                          });
                        }}
                      >
                        CW                           
                      </button>
                      </Tooltip>
                      {" "}
                      <Tooltip title="Current Month">
                      <button
                        type="button"                           
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_MONTH" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_MONTH",
                          });
                        }}
                      >
                        CM
                        
                      </button>
                      </Tooltip>{" "}
                      <Tooltip title="Current Year">
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_YEAR" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_YEAR",
                          });
                        }}
                      >
                        CY                            
                      </button>
                      </Tooltip>{" "}
                      <Tooltip title="Custom Date Selection">
                      <button
                        type="button"
                        className={
                          filters.durationFilter == "CUSTOM_DURATION" ||
                          modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btnColorStylesSecondaryNew btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                          setCount(1);
                          setFilters({
                            ...filters,
                            durationFilter: "CUSTOM_DURATION",
                          });
                        }}
                      
                      >
                        {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                          <img
                            src={filterClockIcon}
                            alt="clock"
                            style={{
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          />
                        ) : (
                          <img
                            src={filterClockIconDe}
                            alt="clock"
                            style={{
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          />
                        )}                           
                      </button>{" "}
                      </Tooltip>
                  </div>
                </div>
               
              </div>
            </Col> */}
          </Row>
          {/* )}  */}

          

              <Row style={{marginTop:"-20px", }}>
                <Col xl={12} md={12} sm={12}>
                  <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",}}>
                      <div className="my-3" style={{ display: "flex", justifyContent: "center", flexDirection: "row", height: "40px",marginTop: "-50px", }}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width:"250px"}} className="me-1" >
              <input type="search" style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchText}
                onKeyPress={handleKeyPress}
                onChange={(e) => { setSearchText(e.target.value); }}
                placeholder="Search User Detail"
              />
            </div>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}  >
                          <button ref={inputRef}
                            style={{ marginLeft: "2px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", }}
                            className="btn btnColorStylesNew me-3"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:10}}>
                      
                        
                        <div className="my-0" style={{ height: "0px", display: "flex", alignItems:"center", marginTop: "0x",  }}>
                          <div className=" mt-0 mb-0 mr-0 ms-auto pe-1 border-end border-secondary" >
                            <div style={{ float: "right" }}>
                            {["ALL LIST","ACTIVE LIST", "DELETED LIST"].map((type) => (
                              <button
                                key={type}
                                type="button"
                                style={{
                                  fontWeight: 700,
                                  // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                                  // color: selectedValue === type ? "#fff" : "#000",
                                  marginRight:"5px"
                                }}
                                className={`btn btn-sm ${
                                  selectedValue === `${type}_USER_LIST` ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm"
                                }`}
                                id={type}
                                onClick={() => handleUserClick(type)} // Pass type to handler
                              >
                                {type.charAt(0) + type.slice(1).toLowerCase()} {/* Format button label */}
                              </button>
                            ))}
                            </div>
                          </div>
                        </div>

                        <div className="mt-0 mb-0 mr-0 ms-auto pe-2 " style={{ textAlign: "center",  }}>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == ""
                                ? "btn btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("")}
                            data-tooltip-id="allBl"
                          >
                            All Data
                            <ReactTooltip
                              id="allBl"
                              place="top"
                              content="All"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "STARTDATE"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("STARTDATE")}
                            data-tooltip-id="Start Date"
                          >
                            Start Data
                            <ReactTooltip
                              id="Start Date"
                              place="top"
                              content="Start Date"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ENDDATE"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("ENDDATE")}
                            data-tooltip-id="End Date"
                          >
                            End Data
                            <ReactTooltip
                              id="End Date"
                              place="top"
                              content="End Date"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "DISTANCEDATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter(" DISTANCEDATA")}
                            data-tooltip-id=" Distance Data"
                          >
                            Distance Data
                            <ReactTooltip
                              id=" Distance Data"
                              place="top"
                              content=" Distance Data"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ERRORDATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("ERRORDATA")}
                            data-tooltip-id="Error Data"
                          >
                            Error Data
                            <ReactTooltip
                              id="Error Data"
                              place="top"
                              content="Error Data"
                              style={{ background: "black" }}
                            />
                          </button>

                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ALERTDATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("ALERTDATA")}
                            data-tooltip-id="Alert Data"
                          >
                            Alert Data
                            <ReactTooltip
                              id="Alert Data"
                              place="top"
                              content="Alert Data"
                              style={{ background: "black" }}
                            />
                          </button>

                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "MANUALDATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("MANUALDATA")}
                            data-tooltip-id="Manual Data"
                          >
                            Manual Data
                            <ReactTooltip
                              id="Manual Data"
                              place="top"
                              content="Manual Data"
                              style={{ background: "black" }}
                            />
                          </button>

                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "STRESSTRAIN"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("STRESSTRAIN")}
                            data-tooltip-id="Stres Strain"
                          >
                            Stress Strain Data
                            <ReactTooltip
                              id="Stres Strain"
                              place="top"
                              content="Stres Strain"
                              style={{ background: "black" }}
                            />
                          </button>
                        </div>

                      </div>
                 
                  </div>

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position: "sticky", left: -2, zIndex: 2, background:"white" }} >
                            User Details                            
                          </th>
                          <th className="cardHeader"     style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position: "sticky",
                              left: 120,
                              zIndex: 2,
                              background:"white",
                            }} >
                            Ride Details
                          </th>
                          <th colSpan={3} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }}  >
                            Attributes
                          </th>
                         
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                            Score Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Risk Data
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Behaviour Data
                          </th> 
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Timings
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                          Start Location
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          End Location
                          </th> 
                          <th colSpan={2} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Data Count
                          </th>      
                              
                          
                        </tr>
                      </thead>

                      <tbody>
                        {/* {dataLength > 0 ? (
                          (summaryDataList || []).map((apiData, index) => ( */}
                            <tr 
                            // key={index}
                            >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position: "sticky", left: -2, zIndex: 2, backgroundColor: "white", }} >
                                <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                  </div>                          
                                  <div className="second-div-flex" >     
                                      <span className="span-tag-name" style={{whiteSpace:"nowrap"}} > Rajendra Prasad
                                        {/* {apiData.firstName}  */}
                                        </span>
                                  </div>
                                  </div>
                                  
                                  <div className="div-flex" style={{marginTop:"10px"}} >
                                  <div>
                                    <p className="p-tag-username"> 007
                                      {/* {apiData.username}  */}
                                      </p>
                                  </div>
                                  <div style={{marginLeft:"4px"}}>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > Active
                                    {/* {apiData.userStatus} */}
                                    </button>
                                  </div>
                                  </div>
                                

                                <div  className="div-flex-margin" >
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        {apiData.deviceType == "ANDROID" ? (
                                          <img src={andIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} /> 
                                        ) : apiData.deviceType == "IOS" ? (
                                          <img src={iosIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        ) : (
                                          <img src={noDeviceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        )}
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {/* {(apiData.deviceType == "ANDROID") || (apiData.deviceType == "IOS" ) ? "Mobile" : (apiData.deviceType == "ANDROID_SDK") || (apiData.deviceType == "IOS_SDK" )  ?  "SDK" : "Other"} */}
                                      </div>
                                    </div>     
                                    <div className="div-spacing" > | </div>
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        <img src={FTwheelerIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {apiData.vehicleType === "FOUR_WHEELER" 
                                          ? "4W" 
                                          : apiData.vehicleType === "TWO_WHEELER" 
                                          ? "2W" 
                                          : "-"}
                                      </div>
                                    </div>
                                </div>

                                <div>
                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActType} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Type : </p>
                                    <p className="p-tag-text-left" >{"-"} </p>
                                  </div>

                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActiveDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Date : </p>
                                    <p className="p-tag-text-left" >{"-"}
                                      {/* {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)} | {formatTime(apiData.createdAtToTimeZone)} */}
                                      </p>
                                  </div>
                                </div>                    
                              </td>

                              <td  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    position:"sticky",
                  left:  120,
                  zIndex: 2,
                  background:"white"
                  }}
                      >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <a href="/appRideList" className="p-tag-text-right" > Ride No:  </a>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  <div className="div-flex-reg-detail">
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > -
                                    {/* {apiData.userStatus} */}
                                    </button>
                                  </div>
                                  </div>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Duration: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Day:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Night</p>
                                  <p className="p-tag-text-left" >
                                    {"-"}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Category:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Activity: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Device: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Status:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Event:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Insert Mode: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Time Zone: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery Start:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery End:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >File Name:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >File Status: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Summary Status: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Summary Time:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                                

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Score:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Grade: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Risk: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Behavior:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >CPP:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Anticipation:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Self Confidence: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving Skill: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Driving Style:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving State: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Usage: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Over Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acc/Brak/Cor:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Start:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >End: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Created: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Inserted:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Modified:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat: 
                                       {"-"}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long: 
                                        {"-"}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat: 
                                        {"-"}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long: 
                                        {"-"}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Start:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >End: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Error:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Alert:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Stress Strain: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Manual: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>                          

                               
                              </td>

                              
                            

                              

                              

                              

                              

                              

                              

                            </tr>
                          {/* ))
                        ) : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}  */}
                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>



       
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="pagination">
                  <a>{pageNumber}</a>
                </div>
                <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
              <div className="pagination ">                  
             <a>{totalPageCount}</a>             
           </div> 
           </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
                ) : null}
            </div>
          </Col>
        </Row>

        <Row style={{marginTop:"-20px", }}>
                <Col xl={12} md={12} sm={12}>                 

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position: "sticky", left: -2, zIndex: 2, background:"white" }} >
                            Basic Detail                            
                          </th>
                          <th className="cardHeader"     style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position: "sticky",
                              left: 120,
                              zIndex: 2,
                              background:"white",
                            }} >
                            Score Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }}  >
                           Timings
                          </th>
                         
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                            Speed
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Location
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Path Details
                          </th> 
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Permissions
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                          Manual Data
                          </th>   
                          <th colSpan={2} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Risk Slot Details
                          </th> 
                          <th colSpan={5}  style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Other Attributes
                          </th>      
                                      
                        </tr>
                      </thead>

                      <tbody>
                        {/* {dataLength > 0 ? (
                          (summaryDataList || []).map((apiData, index) => ( */}
                            <tr 
                            // key={index}
                            >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position: "sticky", left: -2, zIndex: 2, backgroundColor: "white", }} >
                              <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Category:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  {/* <div className="div-flex-reg-detail">
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > -
                                    {apiData.userStatus}
                                    </button>
                                  </div>
                                  </div> */}
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sub Category: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Level: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Device:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sub Type:</p>
                                  <p className="p-tag-text-left" >
                                    {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mode:</p>
                                  <p className="p-tag-text-left" >
                                    {"-"}
                                  </p>
                                </div>
                              </td>

                              <td style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    position:"sticky",
                  left:  120,
                  zIndex: 2,
                  background:"white"
                  }}  >

<div style={{display:"flex"}}>
    <div className="div-flex-reg-detail">
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
        <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
      </div>
      <p className="p-tag-text-right" >Score:  </p>
      <p className="p-tag-text-left" >
      {"-"}
      </p>
    </div>
    
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >Anticipation: </p>
    <p className="p-tag-text-left" >
    {"-"}
    </p>
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >Self Confidence: </p>
    <p className="p-tag-text-left" >
    {"-"}
    </p>
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >Driving Skill:</p>
    <p className="p-tag-text-left" >
    {"-"}
    </p>
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >T.Distance:</p>
    <p className="p-tag-text-left" >
    {"-"}
    </p>
  </div>


  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >T.Duration:</p>
    <p className="p-tag-text-left" >
    {"-"}
    </p>
  </div>

 
 
</td>


                              <td  className="td-container"
                      >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Travel Time:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  {/* <div className="div-flex-reg-detail">
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > -
                                    {apiData.userStatus}
                                    </button>
                                  </div>
                                  </div> */}
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Previous Travel Time: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Ride Time: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Created:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Inserted:</p>
                                  <p className="p-tag-text-left" >
                                    {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Modified:</p>
                                  <p className="p-tag-text-left" >
                                    {"-"}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Speed: 
                                       {"-"}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Risk: 
                                        {"-"}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Speed Limit: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >P.Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >POI:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>


                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Travel Time:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Crash:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"} {"[Val: -]"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex" style={{marginTop:"10px"}}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-5px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat: 
                                       {"-"}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long: 
                                        {"-"}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Urban:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Urban: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Rural: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Rural:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Highwey:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Highwey:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Kms
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Network:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >UPS: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >BG Location: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >DOA Status:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Start Mode:</p>
                                  <p className="p-tag-text-left" >
                                 {"-"}
                                  </p>
                                </div>

                                

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Over Speed:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"} Mins
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Over Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Call: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Call: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Screen: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Screen:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"} Kms
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Negative:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zero: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 1: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 2: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 3: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 4: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                
                               
                              </td>

                              <td className="td-container" >
                                
                              <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Slot 5:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 6: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} 
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 7: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"} | {"-"} Kms | {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 8:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"} | {"-"} Kms | {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 9:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"} | {"-"} Kms | {"-"} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 10:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"} | {"-"} Kms | {"-"} Mins
                                  </p>
                                </div>

                               
                              </td>

                              

                             
                              <td className="td-container" >
                              
                              <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Driving State:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving Style: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Risk Style: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery Lavel:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Traffic Score:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Transport Mode:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Alert:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Id: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Value: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Time:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Kms:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Name:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Acceleration Y:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acceleration Y: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acceleration Z: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>     

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Magneto Meter Y: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>      

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Magneto Meter Y: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>      

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Magneto Meter Z: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>                          

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Gyroscope Y:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Gyroscope Y: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Gyroscope Z: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>      

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sen Rotate: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>    

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >GPS Time: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div> 

                                 <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Ehorizon length: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>                          

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Wifi Usage:  </p>
                                    <p className="p-tag-text-left" >
                                    {"-"}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Usage: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Engine State: </p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >GPS Counts:</p>
                                  <p className="p-tag-text-left" >
                                  {"-"}
                                  </p>
                                </div>

                               

                               
                              </td>


                            </tr>
                          {/* ))
                        ) : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}  */}
                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
