import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const LicenseSubscribtionCBar = ({aggregateData, totalDays}) => {
 
    const floatedDistanceData = parseFloat(aggregateData.totalDistance) || 0;
    const floatedDurationData = parseFloat(aggregateData.totalDuration/60).toFixed(2) || 0;
    const startDate = String(aggregateData.startDateTime).split(" ")[0] || 0;
    const endDate = String(aggregateData.endTimeDate).split(" ")[0] || 0;
    const rideData = [
        { header: 'Total Days', icon: 'ad-2r-calendar-new.svg', value: totalDays, unit: '', alt:'Days' },
        { header: 'Total Orders', icon: 'ad-2r-user-new.svg', value: aggregateData.noofOrder, unit: '', alt:'users'  },
        { header: 'Purchased License Count', icon: 'ad-2r-time-new.svg', value: aggregateData.totalUsageCount, unit: '', alt:'' },
        { header: 'Available License Count', icon: 'ad-2r-time-new.svg', value: aggregateData.availableLicenseCount, unit: '', alt:'' },
        { header: 'Subscription Start Date ', icon: 'ad-2r-time-new.svg', value: startDate, unit: '', alt:''  },      
        { header: 'Subscription End Date', icon: 'ad-2r-red-alerts.svg', value: endDate, unit: '', alt:''  },  
       
      ];
  return (
    <div>
      <Row>
        {rideData.map((data, index) => (
          <Col style={{padding:0}} key={index} xl={2} lg={2} md={4} sm={4} xs={6}>
            <Card className={index != 5 && 'me-2'}>
              <CardHeader className="ps-2 pe-2 pb-1 pt-2 sub_heading_title_card border-0">
                {data.header}
              </CardHeader>
              <CardBody className="body_text border-0 ps-2 pe-2 py-0" style={{ height:'67px',display:"flex",alignItems:"center"}}>
               {data.icon && <img src={require(`../../../assets/images/adminDashboard/${data.icon}`)} alt={data.alt} height={30} width={30} />}
                <div className='sub_heading_title_card' style={{marginLeft:"10px"}}>{data.value} {data.unit}</div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default LicenseSubscribtionCBar;
