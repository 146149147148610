import React, { useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useState } from "react";
import { SC_USER_LOGIN_TYPE } from "../../constant/StorageConstant";
import ValueFormat from "../../util/ValueFormat";
import MySubscriptionTableData from "./MySubscriptionTableData";
import MobileLicenseOrder from "./MobileLicenseOrder";
import Loader from "../../components/Common/loader";
import AdminDashboardCtrlr from "../../controller/AdminDashboardCtrlr";
import { AdminDashboardTopRowItems } from "../../common/data/admin/dashboard";
import LicenseSubscribtionCBar from "../Admin/TrialVersion/LicenseSubscribtionCard";
import iosIcon from "../../assets/images/common/iosTableIcon.svg"
import androidIcon from "../../assets/images/common/androidTableIcon.svg"
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg"
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg"
import UtilDateTime from "../../util/UtilDateTime";
import { displayText } from "../../constant/MessageConstant";
// import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: 400,
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
  },
};

let drivingCategoryList = [{
  drivingSummaryTotalRides:0,
  badDrivingCount:0,
  dangerousDrivingCount:0,
  goodDrivingCount:0,
  quietSafeDrivingCount:0,
  noOfDays:0,
  totalDistance:0,
  totalDuration:0,
  totalHighRiskCountNew:0,
  goodDrivingPercentage:0,
  quitSafeDrivingPercentage:0,
  dangerousDrivingPercentage:0,
  badDrivingPercentage:0
}]

const MySubscription = () => {
  const [userType, setUserType] = useState(
    localStorage.getItem(SC_USER_LOGIN_TYPE)
  );
  function afterOpenModal() {
    
    subtitle.style.color = "#f00";
    setMobileNumber("");
    setPassword("");
    setMobileValid(true);
    setPasswordValid(true);
  }
  function closeModal() {    
    openConfirmationModal();
  }

  const [indexStart, setIndexStart] = useState(0);
  const pageSize = 10;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showLoader, setLoader] = useState(true);
   const [dataLength, setDataLength] = useState(null);
  const [data, setData] = useState(MySubscriptionTableData);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 0,
    androidCount: 0,
    iosCount: 0,
    androidCountSDK: 0,
    iosCountSDK: 0,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
    androidCountPercentage: 0,
    iosCountPercentage: 0,
    androidCountSDKPercentage: 0,
    iosCountSDKPercentage: 0
  });
  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );

  const [aggregateData, setAggregateData] = useState({
    noOfDays:0,
    noofOrder:0,
    startDateTime:0,
    endTimeDate:0,
    availableLicenseCount:0,
    totalUsageCount:0,
  });

  // console.log(data, "subScriptionDataListsubScriptionDataList");
  

  const subscriptionData = async () => {
    setLoader(true);
    setDataLength(null)
    var updatedTableData = [];

    var response = await AdminDashboardCtrlr.MySubscriptionAdmin();
    
    try {
      var responseJSON = JSON.parse(response);
      // console.log("responseJSON", responseJSON)
      if (responseJSON.status == "SUCCESS") {
        setLoader(true);
        var safeRide = 0;
        var responseDATA = responseJSON.data;
        if (responseDATA.length > 0) {
          setDataLength(responseDATA.length);
          setLoader(true);

          for (let i = 0; i < responseDATA.length; i++) {
            const getData = responseDATA[i];
            
            const subscriptionName = ValueFormat.parseAsString( getData.subscriptionDetail.name );
            const availableLicenseCount = ValueFormat.parseAsInt( getData.subscriptionDetail.availableLicenseCount );
            const startDateTimeToTimeZone = ValueFormat.parseAsString( getData.subscriptionDetail.startDateTimeToTimeZone ) ?? "-";
            const endDateTimeToTimeZone = ValueFormat.parseAsString( getData.subscriptionDetail.endDateTimeToTimeZone ) ?? "-";
            const androidCount = ValueFormat.parseAsInt( getData.subscriptionDetail.androidLicenseCount );
            const androidCountSDK = ValueFormat.parseAsInt( getData.subscriptionDetail.androidSdkLicenseCount);
            const iosCountSDK = ValueFormat.parseAsInt( getData.subscriptionDetail.iosSdkLicenseCount);
            const iosCount = ValueFormat.parseAsInt(getData.subscriptionDetail.iosLicenseCount);
            const orderCount = ValueFormat.parseAsInt(getData.subscriptionDetail.orderCount);
            const totalUsageCount= ValueFormat.parseAsInt(getData.subscriptionDetail.purchaseLicenseCount);
            const androidCountPercentage = ((androidCount / availableLicenseCount)*100).toFixed(0);
            const iosCountPercentage = ((iosCount / availableLicenseCount)*100).toFixed(0);
            const androidCountSDKPercentage = ((androidCountSDK / availableLicenseCount)*100).toFixed(0);
            const iosCountSDKPercentage = ((iosCountSDK / availableLicenseCount)*100).toFixed(0);
            

            const tableTotalUserList = ValueFormat.parseAsObject(getData.userSummary);
            var totalDeletedUserCountUsersCount = ValueFormat.parseAsInt(
              tableTotalUserList.deletedUserCount
            );
            const totalUserCount = ValueFormat.parseAsInt(tableTotalUserList.totalUserCount);
            const activeUserCount = ValueFormat.parseAsInt(tableTotalUserList.activeUserCount);
            const inactiveUserCount = ValueFormat.parseAsInt(tableTotalUserList.inactiveUserCount);
            const unregisteredUserCount = ValueFormat.parseAsInt(tableTotalUserList.unregisteredUserCount);
            const totalRegisteredUsersCount = activeUserCount + inactiveUserCount;
            const totalDeactivatedUsersCount = ValueFormat.parseAsInt(tableTotalUserList.deactivatedUserCount);
            const totalDays = ValueFormat.parseAsInt(getData.divisionAgeInDay);
            const totalSafeRideCount = ValueFormat.parseAsInt(safeRide);             

            setADTopRowItemsTemp(
              ADTopRowItemsTemp.map((staticObjectTemp) => {
                var isTypeMatched = false;
                var totalCount = 0;
                if (staticObjectTemp.type === "TOTAL") {
                  isTypeMatched = true;
                  totalCount = totalUserCount;
                } else if (staticObjectTemp.type === "ACTIVE") {
                  isTypeMatched = true;
                  totalCount = activeUserCount;
                } else if (staticObjectTemp.type === "INACTIVE") {
                  isTypeMatched = true;
                  totalCount = inactiveUserCount;
                  // console.log("inac", inactiveUserCount);
                } else if (staticObjectTemp.type === "UNREGISTER") {
                  isTypeMatched = true;
                  totalCount = unregisteredUserCount;
                } else if (staticObjectTemp.type === "PENDING") {
                  isTypeMatched = true;
                  totalCount = registeredUserCount;
                } else if (staticObjectTemp.type === "CATEGORY") {
                  isTypeMatched = true;
                  totalCount = totalSafeRideCount;
                } else if (staticObjectTemp.type === "DEACTIVATED") {
                  isTypeMatched = true;
                  totalCount = totalDeactivatedUsersCount;
                } else if (staticObjectTemp.type === "REGISTERED") {
                  isTypeMatched = true;
                  totalCount = totalRegisteredUsersCount;
                }else if (staticObjectTemp.type === "DELETED") {
                  isTypeMatched = true;
                  totalCount = totalDeletedUserCountUsersCount;
                }
    
                if (isTypeMatched === true) {
                  let staticObjectTempReplace = {
                    totalCount: totalCount,
                  };
                  return { ...staticObjectTemp, ...staticObjectTempReplace };
                } else {
                  return staticObjectTemp;
                }
    
              })
            );

            setSubcriptionDataList((prev) => {
              return {
                ...prev,
                subscriptionName: subscriptionName ?? "-",
                orderCount: orderCount ?? 0,
                totalCount: availableLicenseCount ?? 0,
                startDate: startDateTimeToTimeZone ?? "-",
                endDate: endDateTimeToTimeZone ?? "-",
                androidCount: androidCount ?? 0,
                iosCount: iosCount ?? 0,
                androidCountSDK: androidCountSDK ?? 0,
                iosCountSDK: iosCountSDK ?? 0,
                androidCountPercentage: androidCountPercentage ?? 0,
                iosCountPercentage: iosCountPercentage ?? 0,
                androidCountSDKPercentage: androidCountSDKPercentage ?? 0,
                iosCountSDKPercentage: iosCountSDKPercentage ?? 0
              };
            });

            setAggregateData((prev) => {
              return{
                ...prev,
                noOfDays: totalDays,
                noofOrder : orderCount,
                startDateTime:startDateTimeToTimeZone,
                endTimeDate:endDateTimeToTimeZone,
                availableLicenseCount : availableLicenseCount,
                totalUsageCount : totalUsageCount,
              }
            })

            const orderHistoryList = ValueFormat.parseAsArray(
              getData.orderList
            );
            // console.log(orderHistoryList,"orderHistoryList")
            const subscriptionId = ValueFormat.parseAsString(
              getData.subscriptionDetail.subscriptionId
            );
            setSubscriptionId(subscriptionId);

            for (let r = 0; r < orderHistoryList.length; r++) {

              const getOrderData = orderHistoryList[r];
              // console.log(getOrderData, "getOrderDatagetOrderData");
              
              const androidLicenseCount = ValueFormat.parseAsInt( getOrderData.androidLicenseCount );
              const iosLicenseCount = ValueFormat.parseAsInt( getOrderData.iosLicenseCount );
              const androidLicenseCountSDK = ValueFormat.parseAsInt( getOrderData.androidSdkLicenseCount );
              const iosLicenseCountSDK = ValueFormat.parseAsInt( getOrderData.iosSdkLicenseCount );
              const orderName = ValueFormat.parseAsString(getOrderData.name);
              const paymentMode = ValueFormat.capitalizeFirstLetterAndFormat( ValueFormat.parseAsString(getOrderData.paymentMode) );
              const id = ValueFormat.parseAsString(getOrderData.id);
              // const subscriptionId = ValueFormat.parseAsString(getOrderData.subscriptionId );
              const totalLicenseCount = ValueFormat.parseAsInt(getOrderData.totalLicenseCount );
              const date = ValueFormat.parseAsString( getOrderData.createdAtToTimeZone );

              // console.log(orderName,androidLicenseCount,iosLicenseCount, subscriptionId , totalLicenseCount, "totalLicenseCounttotalLicenseCount");
              

              var userDetails = {
                id: id,
                date: date,
                orderName: orderName,
                androidLicenseCount: androidLicenseCount,
                iosLicenseCount: iosLicenseCount,
                paymentMode: paymentMode,
                subscriptionId: subscriptionId,
                totalLicenseCount: totalLicenseCount,
                iosLicenseCountSDK: iosLicenseCountSDK,
                androidLicenseCountSDK: androidLicenseCountSDK
              };              
              updatedTableData.push(userDetails);
            }
            setLoader(false);
          }
          setLoader(false);

          setData(updatedTableData);
        } else {
          setDataLength(0)
          setLoader(false);
          
        }
      } else {
        setDataLength(0)
        setLoader(false);

        console.error("Failed to fetch data");
      }
    } catch (e) {
      setLoader(false);

      console.error("Catch Error : ", e);
    }
  };
  useEffect(() => {
    subscriptionData();
  }, []);

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "white",
      position: "sticky",
      height: "20px",
      fontWeight: 600,
      fontSize: "12px",
    },
    tbody: {
      display: "block",
      height: "250px",
      overflow: "auto",
    },
  };


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start align-items-center  text-black">
            <h5 className="mb-0">User Licences</h5>
          </Col>
        </Row>
        <Row className="adminmenu-mt">
              <Col  xl={3} lg={4} md={12}>
                <Card>
                  <CardBody style={{}} className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {ADTopRowItemsTemp.map((item, index) => (
                            <tr key={index} className="">
                              <td style={{ padding: "4.7px 20px", paddingLeft: "12px", }} >
                                <div style={{ display: "flex", justifyContent: "space-between", verticalAlign: "middle", marginBottom: "0px", marginTop: "0px", }} >
                                  <p style={{ fontSize: "14px", marginBottom: "0px", marginLeft:
                                        index == 2 || index == 3 ? "30px" : index == 1 || index == 4 ||  index == 5 ? "15px"  : "0px",
                                    }}
                                  >
                                    {item.title}
                                  </p>                                 
                                </div>
                              </td>

                              <td style={{ padding: "4.7px 20px", }} >
                                <span style={{ float: "right", fontWeight: 700 }} >
                                {item?.totalCount}
                                </span>
                              </td>
                            </tr>
                         ))} 
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={9} lg={8} md={12}>
                  <Row >
                    <Card style={{height:130}}>
                      <CardBody className="body_text" style={{ padding: "10px", height: "100px" }} >
                        <div className="mt-3" style={{ display: "flex", justifyContent: "space-between", }} >
                          <div style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.androidCountPercentage ? subScriptionDataList.androidCountPercentage : "-"}
                                text={`${subScriptionDataList.androidCountPercentage == "NaN" ? "0" : subScriptionDataList.androidCountPercentage}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#19543e",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                                Android License
                              <br />
                              <span>
                                {subScriptionDataList.androidCount} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>

                        

                          <div style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>
                            
                              <CircularProgressbar
                                value={subScriptionDataList.iosCountPercentage}
                                text={`${subScriptionDataList.iosCountPercentage == "NaN" ? "0" : subScriptionDataList.iosCountPercentage}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#FFBF00",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 700, }} >
                                IOS License
                              <br />
                              <span>
                                {subScriptionDataList.iosCount +
                                  " / " +
                                  subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>                        

                          <div style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.androidCountSDKPercentage}
                                text={`${subScriptionDataList.androidCountSDKPercentage == "NaN" ? "0" : subScriptionDataList.androidCountSDKPercentage}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#E21212",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                              Android SDK License
                              <br />
                              <span>
                                {subScriptionDataList.androidCountSDK} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>

                          <div style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.iosCountSDKPercentage}
                                text={`${subScriptionDataList.iosCountSDKPercentage == "NaN" ? "0" : subScriptionDataList.iosCountSDKPercentage}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#E21212",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex",flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                              IOS SDK License
                              <br />
                              <span>
                                {subScriptionDataList.iosCountSDK} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                
                <Row style={{marginTop:"-11px"}}>
                  <LicenseSubscribtionCBar aggregateData={aggregateData} totalDays={aggregateData.noOfDays} />
                </Row>                 
                
              </Col>              
          </Row>

          {userType == "SUPER_ADMIN" && (
            <div className="col-md-12 col-sm-12 d-flex justify-content-end" style={{ paddingTop: '10px' }}>
              <div onClick={() => setIsOpen(true)} role="button">
                <button className="btn btn-primary">Add Order</button>
              </div>
            </div>
          )}

          <Row>
          <Col className="col-12 mt-4">              
              <CardBody style={{ padding: "0px",marginTop:"-10px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                  <div style={{ position: "relative", maxHeight: 490, overflowY: "auto", borderCollapse: "collapse", }} >
                      <Table bordered style={TableStyle.table}>
                        <thead style={{ position: "sticky" }}>
                          <tr>
                            <th className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Order Details
                            </th>
                            <th className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Order Summary
                            </th>
                            <th className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Order Line Item Details
                            </th>

   
                          </tr>
                        </thead>

                        <tbody>
                          {dataLength > 0 ? (
                            data.map((orderValue, index) => (
                              <tr 
                              key={index}
                              >
                                <td style={{ fontSize: "10px", verticalAlign: "middle", }} >
                                  <div style={{  display: "flex", gap:"30px"}} >
                                    <div style={{ display: "flex", alignItems: "center", }} >
                                      <div style={{ display: "flex",  }} >
                                        <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", marginLeft: "12px", fontSize: "11px", gap:"10px" }} >
                                          <span>Name:</span>
                                          <span style={{ fontSize: "11px", color: "#5156BE",  marginBottom: "0px", fontWeight: 700, cursor: "default", }} >
                                            {orderValue.orderName}
                                          </span>
                                    
                                      </div>
                                    </div>
                                  </div>                     
                                </td>

                                <td  style={{ textAlign: "center", fontSize: "10px", verticalAlign: "middle", borderLeft: "0px", }} >                                
                                  <div style={{ display: "flex", gap:"10px",}} >
                                    <div style={{ display: "flex", }} >
                                      <div style={{ display: "flex", alignItems:"center" }}>
                                      <div>
                                        <img src={ActiveDate} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                      </div>
                                        <div style={{ marginLeft: "10px", fontSize: "11px", display:"flex", alignItems:"center", gap:"10px"}} >    
                                        <span>Date:</span>                                      
                                        {UtilDateTime.formatDateInt(orderValue.date).split(" ")[0]}
                                        </div>
                                      </div> 
                                    </div>
                                    <div style={{ display: "flex", }} >
                                      <div style={{ display: "flex", alignItems:"center" }}>
                                      <div style={{ display: "flex",}} >
                                        <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                      </div>
                                        <div style={{ marginLeft: "10px", fontSize: "11px", display:"flex", gap:"10px" }} >
                                          <span>Total License:</span>
                                        {parseInt(orderValue.totalLicenseCount)}
                                        </div>
                                      </div> 
                                    </div>
                                  </div>
                                </td>

                                <td  style={{ textAlign: "center", fontSize: "10px", verticalAlign: "middle", borderLeft: "0px", }} >                                
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex", }} >
                                      <div style={{ display: "flex" }}>
                                        <div style={{ marginLeft: "10px", fontSize: "11px", display:"flex", gap:"10px", alignItems:"center" }} >
                                        <img src={androidIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                        <span>Mobile License:</span>
                                        {parseInt(orderValue.androidLicenseCount)}
                                        </div>
                                        <div style={{ marginLeft: "10px", fontSize: "11px", display:"flex", gap:"10px", alignItems:"center"}} >
                                        <img src={iosIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                        <span>Mobile License:</span>
                                        {parseInt(orderValue.iosLicenseCount)}
                                        </div>
                                        <div style={{ marginLeft: "10px", fontSize: "11px", display:"flex", gap:"10px", alignItems:"center"}} >
                                        <img src={androidIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                        <span>SDK License:</span>
                                        {parseInt(orderValue.androidLicenseCountSDK)}
                                        </div>
                                        <div style={{ marginLeft: "10px", fontSize: "11px", display:"flex", gap:"10px", alignItems:"center"}} >
                                        <img src={iosIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                        <span>SDK License:</span>
                                        {parseInt(orderValue.iosLicenseCountSDK)}
                                        </div>
                                      </div> 
                                    </div>
                                  </div>
                                </td>


                              </tr>
                          ))
                          ) : dataLength === 0 ? ( 
                            <tr>
                              {" "}
                              <td colSpan="11">
                                <div
                                  style={{
                                    color: "red",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.DATA_NOT_AVAILABLE}
                                </div>
                              </td>
                            </tr>
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="11">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </Table>
          {/* 
                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )} */}
        </div>
                  </Col>
                </Row>
              </CardBody>
            {/* </Card> */}
          </Col>
        </Row>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={false}
          style={customStyles}
          overlayClassName="custom-modal-overlay"
          contentLabel="Example Modal"
        >
          <MobileLicenseOrder
            setIsOpen={setIsOpen}
            subscriptionId={subscriptionId}
            subscriptionData={subscriptionData}
          />
        </Modal>
      </Container>
    </div>
  );
};

export default MySubscription;
