

import React, { useEffect, useRef, useState } from "react";

import Tooltip from '@mui/material/Tooltip';
import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
// import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap"

import CountUp from "react-countup";

/** import Mini Widget data */
import { AdminDashboardTopRowItems, SAdminDashboardTopRowItems } from "../../common/data/admin/dashboard";
import { SuperAdminDashboardTopRowItems } from "../../common/data/admin/dashboard";

import ValueFormat from "../../util/ValueFormat.js";

import { useSelector } from "react-redux";
import { layoutTypes } from "../../constants/layout";
import giftBox from "../../assets/images/giftbox.png";
import Loader from "../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import { Link } from "react-router-dom";
import ExportScreenPdf from "../../components/ExportScreenPdf/index.js";
import { displayText } from "../../constant/MessageConstant"
import { SC_LOCK_STATUS, SC_USER_FIRST_NAME } from "../../constant/StorageConstant";
import { Col as COL } from "react-grid-system";
import RidesKmHrsScoreCBar from "../Admin/TrialVersion/RidesKmHrsScoreCBar.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AdminDashboardCtrlr from "../../controller/AdminDashboardCtrlr.js";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup.js";
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg";
import SuperAdminDashboardCtrlr from "../../controller/SuperAdminCtrlr.js";
import "../../pages/SuperAdminAmazon/SuperAdminStyles.css"
import UtilDateTime from "../../util/UtilDateTime.js";

import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActType from "../../assets/images/superAdminIcons/actType.svg";
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";
import UsernameVerified from "../../assets/images/superAdminIcons/UserNameVerified.svg";
import PasswordVerified from "../../assets/images/superAdminIcons/passwordVerified.svg";
import FirstnameVerified from "../../assets/images/superAdminIcons/FirstnameVerified.svg";
import ApprovedStatus from "../../assets/images/superAdminIcons/ApprovedStatus.svg";
import ValidityStatus from "../../assets/images/superAdminIcons/Validity.svg";
import DownloadStatus from "../../assets/images/superAdminIcons/downloadStatus.svg";
import ErrorStatus from "../../assets/images/superAdminIcons/errorStatus.svg";
import DriverRides from "../../assets/images/superAdminIcons/Driver.svg";
import PassengerRides from "../../assets/images/superAdminIcons/passenger.svg";
import InvalidRides from "../../assets/images/superAdminIcons/Invalied.svg";
import AbnormalRides from "../../assets/images/superAdminIcons/abnormal.svg";
import DeletedRides from "../../assets/images/superAdminIcons/deleted.svg";
import LRName from "../../assets/images/superAdminIcons/LSName.svg";
import LRDistance from "../../assets/images/superAdminIcons/LRDistance.svg";
import LRDuration from "../../assets/images/superAdminIcons/LRDuration.svg";
import LRCategory from "../../assets/images/superAdminIcons/category.svg";
import LRStartTime from "../../assets/images/superAdminIcons/StartTime.svg";
import LDDeviceId from "../../assets/images/superAdminIcons/deviceId.svg";
import LDOrderId from "../../assets/images/superAdminIcons/orderIdNewIcon.svg";
import MDTotal from "../../assets/images/superAdminIcons/MDTotal.svg";
import MDVersion from "../../assets/images/superAdminIcons/MDVersion.svg";
import MDModel from "../../assets/images/superAdminIcons/MDModel.svg";
import MDRegDate from "../../assets/images/superAdminIcons/MDRegDate.svg";
import MSAppVersion from "../../assets/images/superAdminIcons/MSAppVersion.svg";
import MSLastSession from "../../assets/images/superAdminIcons/MSLastSession.svg";
import MSLastActivity from "../../assets/images/superAdminIcons/MSLastActivity.svg";
import WSTotal from "../../assets/images/superAdminIcons/WSTotal.svg";
import WSRemoteAdd from "../../assets/images/superAdminIcons/WSRemoteAdd.svg";
import WSLastSession from "../../assets/images/superAdminIcons/WSLastSession.svg";
import AppUpdate from "../../assets/images/superAdminIcons/AppUpdate.svg";
import MapUpdated from "../../assets/images/superAdminIcons/MapUpdated.svg";
import NoActivity from "../../assets/images/superAdminIcons/NoActivity.svg";
import PreLoginCrash from "../../assets/images/superAdminIcons/PreLoginCrash.svg";
import PreLoginOthers from "../../assets/images/superAdminIcons/PreLoginOthers.svg";
import PostLoginCrash from "../../assets/images/superAdminIcons/PostLoginCrash.svg";
import PostLoginOthers from "../../assets/images/superAdminIcons/PostLoginOthers.svg";
import LoginPermissions from "../../assets/images/superAdminIcons/LoginPermissions.svg";
import PeriodicPermissions from "../../assets/images/superAdminIcons/PeriodicPermissions.svg";
import AppUpdatePush from "../../assets/images/superAdminIcons/AppUpdatePush.svg";
import MapUpdatePush from "../../assets/images/superAdminIcons/MapUpdatePush.svg";
import NoActivityPush from "../../assets/images/superAdminIcons/NoActivityPush.svg";
import WelcomeSMS from "../../assets/images/superAdminIcons/WelcomeSMS.svg";
import LoginOTPSms from "../../assets/images/superAdminIcons/LoginOTPSms.svg";
import Fpwdotp from "../../assets/images/superAdminIcons/Fpwdotp.svg";
import AppUpdateSMS from "../../assets/images/superAdminIcons/AppUpdateSMS.svg";
import MapUpdateSMS from "../../assets/images/superAdminIcons/MapUpdateSMS.svg";
import NoActivitySMS from "../../assets/images/superAdminIcons/NoActivitySMS.svg";
import Converter from "../../components/Common/EncodeDecode.js";


const SuperAdminAmazonDashboard = () => {

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "white",
      position: "sticky",
      height: "20px",
      fontWeight: 600,
      fontSize: "12px",
    },
    tbody: {
      display: "block",
      height: "250px",
      overflow: "auto",
    },
  };

  let drivingCategoryList = [{
    drivingSummaryTotalRides:0,
    badDrivingCount:0,
    dangerousDrivingCount:0,
    goodDrivingCount:0,
    quietSafeDrivingCount:0,
    noOfDays:0,
    totalDistance:0,
    totalDuration:0,
    totalHighRiskCountNew:0,
    goodDrivingPercentage:0,
    quitSafeDrivingPercentage:0,
    dangerousDrivingPercentage:0,
    badDrivingPercentage:0
}]
  
  // New
  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );

  const [ drivingDataList, setDrivingDataList] = useState(drivingCategoryList);

  const [aggregateData, setAggregateData] = useState({
    noOfDays:0,
    drivingSummaryTotalRides:0,
    totalDistance:0,
    totalDuration:0,
    totalHighRiskCountNew:0,
    totalUsers:0,
  });

  const [totalDays, setTotalDays] = useState(0);

  const [selectedValue, setSelectedValue] = useState("ALL_USER_LIST");
  
  const [searchText, setSearchText] = useState("");

  const inputRef = useRef();

  const [filters, setFilters] = useState({
    durationFilter: "ALL",
    startDateTime: "",
    endDateTime: "",
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  const [startDateTime, setStartDateTime] = useState("");

  const [endDateTime, setEndDateTime] = useState(""); 

  const [filter, setFilter] = useState("");

  const [count, setCount] = useState(0);

  const [summaryDataList, setSummaryDataList] = useState([]);

  const [totalPageCount, setTotalPageCount] = useState();

  const [nextShow, setNextShow] = useState();

  const [prevShow, setPrevShow] = useState();
 
  // New End

  const [rideRiskData, setRideRiskData] = useState({
    rideRiskXaxis: [],
    rideSeries: [],
    riskAmberSeries: [],
    riskRedSeries: [],
  });
  const [dailyData, setDailyData] = useState(false);
  const [userUsagePer, setUserUsagePer] = useState([]);
  const [activeDriverData, setActiveDriverData] = useState([]);
  const [activeDriverPie, setActiveDriverPie] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("CURRENT_YEAR");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [pdUsageData, setPdUsageData] = useState(false);
  const [rcChart, setRcChart] = useState(null);
  const [rccData, setRccData] = useState(null);
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const pageSize = 5;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };


  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getOverAllSummary(0);
  };

  const handleUserClick = (type) => {
    const selectedMapping = {
      ALL:"ALL_USER_LIST",
      ACTIVE: "ACTIVE_USER_LIST",
      INACTIVE: "INACTIVE_USER_LIST",
      UNREGISTERED: "UNREGISTERED_USER_LIST",
      DEACTIVATED: "DEACTIVATED_USER_LIST",
      DELETED:"DELETED_USER_LIST",
    };
    setSelectedValue(selectedMapping[type]);
    // setIndexStart(0);
    // setPageNumber(1);    
  };
  

  const [dataLength, setDataLength] = useState(null);

  const getTotalValues = async () => {   

    setShowLoader((current) => current + 1);

    var result = await SuperAdminDashboardCtrlr.getV1SuperAdminDashboardSummary(
      filters.startDateTime,
      filters.endDateTime,
      filters.durationFilter,
    );

    setShowLoader((current) => current - 1);

    var resultJSON = JSON.parse(result);
    // console.log(resultJSON,"AdminAdminn")
    var status = resultJSON.status; 

    if (status == "SUCCESS") {

      var resultJSONData = resultJSON?.data;  
      // console.log(resultJSONData,"resultJSONDatahhkjjh")        
      var safeRide = 0;
      var userCountPercentages = [];
      var getTheValueTopFore = [];
      // console.log("AdminTopRows:",(resultJSONData));
      let totalDays = 0;     
      if (resultJSONData.length > 0) {
      resultJSONData.map((apiResultTemp) => {

        var tableTotalUserList = ValueFormat.parseAsObject(apiResultTemp.userSummary);
        
        var totalUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.totalUserCount)
        );
        
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.registeredUserCount)
        );

        var totalDeactivatedUsersCount = ValueFormat.parseAsInt(
          tableTotalUserList.deactivatedUserCount
        );

        var totalDeletedUserCountUsersCount = ValueFormat.parseAsInt(
          tableTotalUserList.deletedUserCount
        );

        totalDays = parseInt(ValueFormat.parseAsInt(apiResultTemp.divisionAgeInDay));  
        
        let drivingSummary = ValueFormat.parseAsObject(apiResultTemp.drivingSummary);
        let drivingCategoryCount = ValueFormat.parseAsObject(apiResultTemp.drivingSummary.drivingCategoryDetail);
        const drivingSummaryTotalRides = ValueFormat.parseAsString(drivingSummary.totalRideCount);
        const totalUsers = ValueFormat.parseAsString(drivingSummary.totalUserCount);
        const badDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.badDrivingCount);
        const dangerousDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.dangerousDrivingCount);
        const goodDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.goodDrivingCount);
        const quietSafeDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.quietSafeDrivingCount);
        const noOfDays = ValueFormat.parseAsString(apiResultTemp.divisionAge);
        const totalDistance = ValueFormat.parseAsFloat(drivingSummary.totalDistanceInKm,2);       
        const totalDuration = ValueFormat.parseAsFloat(drivingSummary.totalDurationInMin,2);
        const totalHighRiskCountNew = ValueFormat.parseAsFloat(drivingSummary.totalHighAlertCount);
        const goodDrivingPercentage = ((goodDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const quitSafeDrivingPercentage = ((quietSafeDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const dangerousDrivingPercentage = ((dangerousDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const badDrivingPercentage = ((badDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
       
        setAggregateData({
          noOfDays,
          drivingSummaryTotalRides,
          totalDistance,
          totalDuration,
          totalHighRiskCountNew,
          totalUsers
        })      

        var totalSafeRideCount = ValueFormat.parseAsInt(safeRide);     
       
        var totalRegisteredUsersCount = activeUserCount + inactiveUserCount;
        
        let sendTheDataList = {
          drivingSummaryTotalRides,
          badDrivingCount,
          dangerousDrivingCount,
          goodDrivingCount,
          quietSafeDrivingCount,
          totalDistance,
          totalDuration,
          totalHighRiskCountNew,
          goodDrivingPercentage,
          quitSafeDrivingPercentage,
          dangerousDrivingPercentage,
          badDrivingPercentage,
        };

        getTheValueTopFore.push(sendTheDataList);

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount,
          registeredUserCount
        );

        setADTopRowItemsTemp(
          ADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalCount = 0;
            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalCount = totalUserCount;
            } else if (staticObjectTemp.type === "ACTIVE") {
              isTypeMatched = true;
              totalCount = activeUserCount;
            } else if (staticObjectTemp.type === "INACTIVE") {
              isTypeMatched = true;
              totalCount = inactiveUserCount;
              // console.log("inac", inactiveUserCount);
            } else if (staticObjectTemp.type === "UNREGISTER") {
              isTypeMatched = true;
              totalCount = unregisteredUserCount;
            } else if (staticObjectTemp.type === "PENDING") {
              isTypeMatched = true;
              totalCount = registeredUserCount;
            } else if (staticObjectTemp.type === "CATEGORY") {
              isTypeMatched = true;
              totalCount = totalSafeRideCount;
            } else if (staticObjectTemp.type === "DEACTIVATED") {
              isTypeMatched = true;
              totalCount = totalDeactivatedUsersCount;
            } else if (staticObjectTemp.type === "REGISTERED") {
              isTypeMatched = true;
              totalCount = totalRegisteredUsersCount;
            }else if (staticObjectTemp.type === "DELETED") {
              isTypeMatched = true;
              totalCount = totalDeletedUserCountUsersCount;
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalCount: totalCount,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }

          })
        );
        
      });
      setTotalDays(totalDays);
      setDrivingDataList(getTheValueTopFore);

      }else{
        setDrivingDataList([
          {
            drivingSummaryTotalRides: 0,
            badDrivingCount: 0,
            dangerousDrivingCount: 0,
            goodDrivingCount: 0,
            quietSafeDrivingCount: 0,
            totalDistance: 0,
            totalDuration: 0,
            totalHighRiskCountNew: 0,
            goodDrivingPercentage: 0,
            quitSafeDrivingPercentage: 0,
            dangerousDrivingPercentage: 0,
            badDrivingPercentage: 0,
          },
        ]);
      }
      
      
    } else {
     
    }
  };




  useEffect(() => {

    getTotalValues();
   
    // getTotalValues();
   
    // getRiskRideCount();
   
  }, [filters.durationFilter,    
    filters.startDateTime,
    filters.endDateTime,
    ]);

  useEffect(() => {
    setPdUsageData();
  }, []);

  useEffect(() => {
    setRcChart();
  }, [currentFilter, indexStart]);

  // useEffect(()=>{
  //   localStorage.removeItem("_grecaptcha")
  // },[])
  //meta title

  const getOverAllSummary = async (indexStartOfSet) => {   
    setShowLoader(true);
    setDataLength(null);
    const transportType = selectedValue;
    var result = await SuperAdminDashboardCtrlr.getSuperAdminV1UserList(
      indexStartOfSet,    
      searchText,      
      selectedValue
    )
    // console.log(result,"resultresult")
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;

    if (status == "SUCCESS") {

      setShowLoader(false);

      var resultJSONData = resultJSON.data;
      
      // console.log(resultJSONData,"resultJSONDataAdmin")
      if (resultJSONData.length > 0) {
        setDataLength(resultJSONData.length);
     

        var summaryData = [];

        resultJSONData.map((value, i) => {

          var totalCount              = ValueFormat.parseAsInt(value.totalCount);

          setTotalPageCount(Math.ceil(totalCount / pageSize));
          setNextShow(totalCount - indexStartOfSet > pageSize);
          var userId                  = ValueFormat.parseAsString(value.userId)
          var profileDetail           = ValueFormat.parseAsObject(value.profileDetail);
          var registrationDetail      = ValueFormat.parseAsObject(value.registrationDetail);
          var licenseDetail           = ValueFormat.parseAsObject(value.licenseDetail);
          var rideDetail              = ValueFormat.parseAsObject(value.rideDetail);
          var lastRideDetail          = ValueFormat.parseAsObject(value.lastRideDetail);
          var mobileDetail            = ValueFormat.parseAsObject(value.mobileDetail);
          var sessionMobileDetail     = ValueFormat.parseAsObject(value.sessionMobileDetail);
          var sessionWebDetail        = ValueFormat.parseAsObject(value.sessionWebDetail);
          var lastNotificationDetail  = ValueFormat.parseAsObject(value.lastNotificationDetail);
          var exceptionAndPermissionDetail  = ValueFormat.parseAsObject(value.exceptionAndPermissionDetail);
          
          
          // ProfileDetail
          var firstName           = ValueFormat.parseAsString(profileDetail.firstName) || "-";
          var username            = ValueFormat.parseAsString(profileDetail.username) || "-";
          var deviceType          = ValueFormat.parseAsString(profileDetail.deviceType) || "-";
          var vehicleType         = ValueFormat.parseAsString(profileDetail.vehicleType) || "-";
          var usernameType        = ValueFormat.parseAsString(profileDetail.usernameType) || "-";
          var createdAtToTimeZone = ValueFormat.parseAsString(profileDetail.createdAtToTimeZone) || "-";
          var userStatus          = ValueFormat.parseAsString(profileDetail.userStatus) || "-";
          var userActive          = ValueFormat.parseAsString(profileDetail.userActive) || "-";

          // Registration Detail
          var firstNameUpdatedStatus   = ValueFormat.parseAsString(registrationDetail.firstNameUpdatedStatus) || "-";
          var passwordUpdatedStatus    = ValueFormat.parseAsString(registrationDetail.passwordUpdatedStatus) || "-";
          var usernameVerifiedStatus   = ValueFormat.parseAsString(registrationDetail.usernameVerifiedStatus) || "-";
          
          // License Detail
          var approvedStatus   = ValueFormat.parseAsString(licenseDetail.approvedStatus) || "-";
          var validityStatus   = ValueFormat.parseAsString(licenseDetail.validityStatus) || "-";
          var downloadStatus   = ValueFormat.parseAsString(licenseDetail.downloadStatus) || "-";
          var errorStatus      = ValueFormat.parseAsString(licenseDetail.errorStatus) || "-";
          var downloadCount    = ValueFormat.parseAsInt(licenseDetail.downloadCount) || 0;
          var licenseOrderId      = ValueFormat.parseAsString(licenseDetail.licenseOrderId) || "-";
          var deviceUniqueId      = ValueFormat.parseAsString(licenseDetail.deviceUniqueId) || "-";
          
          // Ride Detail
          var invalidRideCount   = ValueFormat.parseAsInt(rideDetail.invalidRideCount) || 0;
          var driverRideCount    = ValueFormat.parseAsInt(rideDetail.driverRideCount) || 0;
          var passengerRideCount = ValueFormat.parseAsInt(rideDetail.passengerRideCount) || 0;
          var abnormalRideCount  = ValueFormat.parseAsInt(rideDetail.abnormalRideCount) || 0;
          var deletedRideCount   = ValueFormat.parseAsInt(rideDetail.deletedRideCount) || 0;
          var overallRideCount   = ValueFormat.parseAsInt(rideDetail.overallRideCount) || 0;

          // Last Ride Detail 
          var name                = ValueFormat.parseAsString(lastRideDetail.name) || "-";
          var timeZone            = ValueFormat.parseAsString(lastRideDetail.createdAtToTimeZone) || "-";
          var totalDistanceInKm   = ValueFormat.parseAsInt(lastRideDetail.totalDistanceInKm) || "-";
          var totalDurationInMin  = ValueFormat.parseAsInt(lastRideDetail.totalDurationInMin);
          var category            = ValueFormat.parseAsString(lastRideDetail.category) || "-";

          // Mobile Device Details
          var totalDeviceCount   = ValueFormat.parseAsInt(mobileDetail.totalDeviceCount) || 0;
          var activeDeviceCount  = ValueFormat.parseAsInt(mobileDetail.activeDeviceCount) || 0;
          var lastUniqueId  = ValueFormat.parseAsString(mobileDetail.lastUniqueId) || "-";
          var lastVersionNumber      = ValueFormat.parseAsInt(mobileDetail.lastVersionNumber) || 0;
          var lastModelName          = ValueFormat.parseAsString(mobileDetail.lastModelName) || "-";
          var DeviceLastCreatedAtToTimeZone = ValueFormat.parseAsString(mobileDetail.lastCreatedAtToTimeZone) || "-";

          // Mobile Session Details
          var totalSessionCount   = ValueFormat.parseAsInt(sessionMobileDetail.totalSessionCount) || 0;
          var activeSessionCount  = ValueFormat.parseAsInt(sessionMobileDetail.activeSessionCount) || 0;
          var sessionLastAppVersion      = ValueFormat.parseAsInt(sessionMobileDetail.lastAppVersion) || 0;
          var sessionMobileDeviceType          = ValueFormat.parseAsString(sessionMobileDetail.deviceType) || "-";
          var MobileLastSessionAtToTimeZone = ValueFormat.parseAsString(sessionMobileDetail.lastSessionAtToTimeZone) || "-";
          var sessionLastActivityAtToTimeZone = ValueFormat.parseAsString(sessionMobileDetail.lastActivityAtToTimeZone) || "-";
          var sessionLastDeviceId = ValueFormat.parseAsString(sessionMobileDetail.lastDeviceId) || "-";

          // Web Session Details
          var webTotalSessionCount   = ValueFormat.parseAsInt(sessionWebDetail.totalSessionCount) || 0;
          var webActiveSessionCount  = ValueFormat.parseAsInt(sessionWebDetail.activeSessionCount) || 0;
          var lastTokenStatus      = ValueFormat.parseAsString(sessionWebDetail.lastTokenStatus) || "-";
          var lastRemoteAddress          = ValueFormat.parseAsString(sessionWebDetail.lastRemoteAddress) || "-";
          var webLastSessionAtToTimeZone = ValueFormat.parseAsString(sessionWebDetail.lastSessionAtToTimeZone) || "-";
          var lastActivityAtToTimeZone = ValueFormat.parseAsString(sessionWebDetail.lastActivityAtToTimeZone) || "-";
          
          // Last Push Notification Details

          var lastAppUpdateAtToTimeZone      = ValueFormat.parseAsString(lastNotificationDetail.lastAppUpdateAtToTimeZone) || "-";
          var lastMapUpdateAtToTimeZone      = ValueFormat.parseAsString(lastNotificationDetail.lastMapUpdateAtToTimeZone) || "-";
          var lastNoActivityAtToTimeZone      = ValueFormat.parseAsString(lastNotificationDetail.lastNoActivityAtToTimeZone) || "-";
          
          // exceptionAndPermissionDetail

          var preLoginCrashExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.preLoginCrashExceptionCount) || 0;
          var preLoginOtherExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.preLoginOtherExceptionCount) || 0;
          var postLoginCrashExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginCrashExceptionCount) || 0;
          var postLoginOtherExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginOtherExceptionCount) || 0;

          var postLoginLoginPermissionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginLoginPermissionCount) || 0;
          var postLoginPeriodicalPermissionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginPeriodicalPermissionCount) || 0;

          let statusList = "";

          if (userStatus == "INITIATED" || userStatus == "REGISTERED") {
            statusList = "Unregistered";
          } else if (userStatus == "ACTIVE" && overallRideCount <= 0) {
            statusList = "Inactive";
          } else if (userStatus == "ACTIVE" && overallRideCount > 0) {
            statusList = "Active";
          } else if (userStatus == "DEACTIVATED") {
            statusList = "Deactivated";
          } else {
            statusList = "-";
          }

          if (userActive == "INACTIVE") {
            statusList = "Deleted";
          }
         
          
          
          // console.log(typeof(lastRideDetail.totalDistanceInKm),"downloadCount")

          var resultObject = {

            userId:userId,

            // ProfileDetail
            firstName:firstName,
            username:username,
            deviceType:deviceType,
            vehicleType:vehicleType,
            usernameType:usernameType,
            createdAtToTimeZone:createdAtToTimeZone,
            userStatus:statusList,

            // Registration Detail
            firstNameUpdatedStatus:firstNameUpdatedStatus,
            passwordUpdatedStatus:passwordUpdatedStatus,
            usernameVerifiedStatus:usernameVerifiedStatus,

            // License Detail
            approvedStatus:approvedStatus,
            validityStatus:validityStatus,
            downloadStatus:downloadStatus,
            errorStatus:errorStatus,
            downloadCount:downloadCount,
            licenseOrderId:licenseOrderId,
            deviceUniqueId:deviceUniqueId,

            // Ride Detail
            invalidRideCount:invalidRideCount,
            driverRideCount:driverRideCount,
            passengerRideCount:passengerRideCount,
            abnormalRideCount:abnormalRideCount,
            deletedRideCount:deletedRideCount,

            // Last Ride Detail
            name:name,
            timeZone:timeZone,
            totalDistanceInKm:totalDistanceInKm,
            totalDurationInMin:totalDurationInMin,
            category:category,

            // Mobile Device Details
            totalDeviceCount:totalDeviceCount,
            activeDeviceCount:activeDeviceCount,
            lastUniqueId:lastUniqueId,
            lastVersionNumber:lastVersionNumber,
            lastModelName:lastModelName,
            DeviceLastCreatedAtToTimeZone:DeviceLastCreatedAtToTimeZone,

             // Mobile Session Details
             totalSessionCount:totalSessionCount,
             activeSessionCount:activeSessionCount,
             sessionLastAppVersion:sessionLastAppVersion,
             sessionMobileDeviceType:sessionMobileDeviceType,
             MobileLastSessionAtToTimeZone:MobileLastSessionAtToTimeZone,
             sessionLastActivityAtToTimeZone:sessionLastActivityAtToTimeZone,
             sessionLastDeviceId:sessionLastDeviceId,

             // Web Session Details
             webTotalSessionCount:webTotalSessionCount,
             webActiveSessionCount:webActiveSessionCount,
             lastTokenStatus:lastTokenStatus,
             lastRemoteAddress:lastRemoteAddress,
             webLastSessionAtToTimeZone:webLastSessionAtToTimeZone,
             lastActivityAtToTimeZone:lastActivityAtToTimeZone,

             //Last Push Notification Details
             lastAppUpdateAtToTimeZone:lastAppUpdateAtToTimeZone,
             lastMapUpdateAtToTimeZone:lastMapUpdateAtToTimeZone,
             lastNoActivityAtToTimeZone:lastNoActivityAtToTimeZone,

             // exceptionAndPermissionDetail
             preLoginCrashExceptionCount:preLoginCrashExceptionCount,
             preLoginOtherExceptionCount:preLoginOtherExceptionCount,
             postLoginCrashExceptionCount:postLoginCrashExceptionCount,
             postLoginOtherExceptionCount:postLoginOtherExceptionCount,

             postLoginLoginPermissionCount:postLoginLoginPermissionCount,
             postLoginPeriodicalPermissionCount:postLoginPeriodicalPermissionCount,
            
           
           
          };
          summaryData.push(resultObject);
         
        });
      } else {
        setDataLength(0)
        
      }    

      setSummaryDataList(summaryData);     
      
    } else if (status == "FAILED") {
      setDataLength(0)
      setShowLoader(false);
      

      var code = resultJSON.error.code;

      if (code == "E1206") {
        setShowLoader(false);
        
      
      }
    }

    setShowLoader(false);

    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }
  };

  useEffect(() => {     
    getOverAllSummary(indexStart);
  }, [indexStart,selectedValue]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  function capitalizeLetter  (category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  }
  
  const postSuperAdminTriger = async (type,userId) => {
    setShowLoader(true);
    var response = await SuperAdminDashboardCtrlr.sendSuperAdminTriger(userId, type);
    setShowLoader(false);
    // console.log(response,"response");
    var resultJSON = JSON.parse(response);
    const status = resultJSON.status;
    // console.log(resultJSON,"resultJSON");
    if (status === "SUCCESS") {
      const successMSG = resultJSON.data;
      const successText = successMSG.message;
      ToastService.successmsg(successText);      
    } else if (status === "FAILED") {
      const error = resultJSON.error;
      const message = error.message;
      ToastService.errormsg(message);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid>
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Super Admin Dashboard"
              breadcrumbItem="Super Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />
            <Row className="my-2 py-2">
              <Col xs={6} className="d-flex justify-content-start align-items-center text-black">
                <h5 className="mb-0">Dashboard</h5>
              </Col>
              <Col xs={6}>
                <div className="pb-2 d-flex justify-content-end">
                <div className="ms-auto pe-2 ">
                        <div style={{ float: "right" }}>
                        <Tooltip title="All">
                          <button
                              type="button"                            
                              style={{ fontWeight: 700 }}
                              className={
                                filters.durationFilter == "ALL" &&
                                !modalIsOpen
                                  ? "btn btnColorStylesNew btn-sm me-1"
                                  : "btn btn-soft-secondary btn-sm me-1"
                              }
                              onClick={() => {
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "ALL",
                                });
                              }}
                            >
                              All                           
                            </button>
                            </Tooltip>
                            <Tooltip title="Current Day">
                            <button
                              type="button"                            
                              style={{ fontWeight: 700 }}
                              className={
                                filters.durationFilter == "CURRENT_DAY" &&
                                !modalIsOpen
                                  ? "btn btnColorStylesNew btn-sm"
                                  : "btn btn-soft-secondary btn-sm"
                              }
                              onClick={() => {
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_DAY",
                                });
                              }}
                            >
                              CD                           
                            </button>
                            </Tooltip>
                            {" "}
                            <Tooltip title="Current Week">
                            <button
                              type="button"                            
                              style={{ fontWeight: 700 }}
                              className={
                                filters.durationFilter == "CURRENT_WEEK" &&
                                !modalIsOpen
                                  ? "btn btnColorStylesNew btn-sm"
                                  : "btn btn-soft-secondary btn-sm"
                              }
                              onClick={() => {
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_WEEK",
                                });
                              }}
                            >
                              CW                           
                            </button>
                            </Tooltip>
                            {" "}
                            <Tooltip title="Current Month">
                            <button
                              type="button"                           
                              style={{ fontWeight: 700 }}
                              className={
                                filters.durationFilter == "CURRENT_MONTH" &&
                                !modalIsOpen
                                  ? "btn btnColorStylesNew btn-sm"
                                  : "btn btn-soft-secondary btn-sm"
                              }
                              onClick={() => {
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_MONTH",
                                });
                              }}
                            >
                              CM
                              
                            </button>
                            </Tooltip>{" "}
                            <Tooltip title="Current Year">
                            <button
                              type="button"                            
                              style={{ fontWeight: 700 }}
                              className={
                                filters.durationFilter == "CURRENT_YEAR" &&
                                !modalIsOpen
                                  ? "btn btnColorStylesNew btn-sm"
                                  : "btn btn-soft-secondary btn-sm"
                              }
                              onClick={() => {
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_YEAR",
                                });
                              }}
                            >
                              CY                            
                            </button>
                            </Tooltip>{" "}
                            <Tooltip title="Custom Date Selection">
                            <button
                              type="button"
                              className={
                                filters.durationFilter == "CUSTOM_DURATION" ||
                                modalIsOpen
                                  ? "btn btnColorStylesNew btn-sm"
                                  : "btn btnColorStylesSecondaryNew btn-sm"
                              }
                              onClick={() => {
                                setIsOpen(true);
                                setCount(1);
                                // setFilters({
                                //   ...filters,
                                //   durationFilter: "CUSTOM_DURATION",
                                // });
                              }}
                            
                            >
                              {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                                <img
                                  src={filterClockIcon}
                                  alt="clock"
                                  style={{
                                    height: "18px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                />
                              ) : (
                                <img
                                  src={filterClockIconDe}
                                  alt="clock"
                                  style={{
                                    height: "18px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                />
                              )}                           
                            </button>{" "}
                            </Tooltip>
                        </div>
                      </div>
                  {/* <ExportScreenPdf fileName={userFirstName + "'s" + " Dashboard"} /> */}
                </div>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col  xl={3} lg={4} md={12}>
                <Card>
                  <CardBody style={{}} className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {ADTopRowItemsTemp.map((item, index) => (
                            <tr key={index} className="">
                              <td
                                style={{
                                  padding: "4.7px 20px",
                                  paddingLeft: "12px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    verticalAlign: "middle",
                                    marginBottom: "0px",
                                    marginTop: "0px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                      marginLeft:
                                        index == 2 || index == 3
                                          ? "30px"
                                          : index == 1 ||
                                            index == 4 ||
                                            index == 5
                                          ? "15px"
                                          : "0px",
                                    }}
                                  >
                                    {item.title}
                                  </p>
                                  {/* <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={selectedValue} // Controlled by state
                                    onChange={handleChange} // Handle changes
                                  >
                                    <FormControlLabel
                                      sx={{
                                        padding: "0px",
                                        display: index == 1 ? "none" : "block",
                                        marginBottom: "0px",
                                      }}
                                      value={item.filterKeyValue}
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{ padding: "0px" }}
                                        />
                                      }
                                    />
                                  </RadioGroup> */}
                                 
                                </div>
                              </td>

                              <td
                                style={{
                                  padding: "4.7px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right", fontWeight: 700 }}
                                >
                                  {item.totalCount}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={9} lg={8} md={12}>
              {drivingDataList.map((val,i) => (
                <Row key={i}>
                  <Card style={{height:130}}>
                    <CardBody
                      className="body_text"
                      style={{ padding: "10px", height: "100px" }}
                    >
                      <div
                       className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>                            
                            <CircularProgressbar
                              value={val.goodDrivingPercentage}
                              text={`${val.goodDrivingPercentage == "NaN" ? "0" : val.goodDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                           Good Driving
                            <br />
                            <span>
                              {val.goodDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                       

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>
                           
                            <CircularProgressbar
                              value={val.quitSafeDrivingPercentage}
                              text={`${val.quitSafeDrivingPercentage == "NaN" ? "0" : val.quitSafeDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#FFBF00",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                              fontWeight: 700,
                            }}
                          >
                           Quite Safe Driving
                            <br />
                            <span>
                              {val.quietSafeDrivingCount +
                                " / " +
                                val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                      

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>                            
                            <CircularProgressbar
                              value={val.dangerousDrivingPercentage}
                              text={`${val.dangerousDrivingPercentage == "NaN" ? "0" : val.dangerousDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#E21212",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Dangerous Driving
                            <br />
                            <span>
                              {val.dangerousDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>                            
                            <CircularProgressbar
                              value={val.badDrivingPercentage}
                              text={`${val.badDrivingPercentage == "NaN" ? "0" : val.badDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#E21212",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Bad Driving
                            <br />
                            <span>
                              {val.badDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                ))}
                
                <Row style={{marginTop:"-11px"}}>

                <RidesKmHrsScoreCBar
                    aggregateData={aggregateData}
                    totalDays={totalDays}
                  />

                </Row>
                  
                
              </Col>
              
              </Row>

              <Row style={{ marginTop: "-20px" }}>
              <Col xl={12} md={12} sm={12}>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", }}>
          {/* {selectedValue === "ACTIVE_USER_LIST" ? ( */}
          <div className="my-3"  style={{ height: "40px", display: "flex", marginTop: "-50px", }}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width:"250px"}} >
              <input type="search" style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchText}
                onKeyPress={handleKeyPress}
                onChange={(e) => { setSearchText(e.target.value); }}
                placeholder="Search"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
              <button ref={inputRef}
                style={{ marginLeft: "5px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column",  }}
                className="btn btnColorStylesNew me-3"
                type="button"
                onClick={handleSearch}
              >
                <i className="bx bx-search-alt align-middle"></i>
              </button>
            </div>           
            
          </div>
         


          <div className="my-3" style={{ height: "40px", display: "flex", marginTop: "-50px", }}>
            <div className="ms-auto pe-0  border-secondary">
              <div style={{ float: "right" }}>
              {["ALL","ACTIVE", "INACTIVE", "UNREGISTERED", "DEACTIVATED", "DELETED"].map((type) => (
                <button
                  key={type}
                  type="button"
                  style={{
                    fontWeight: 700,
                    // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                    // color: selectedValue === type ? "#fff" : "#000",
                    margin:"5px"
                  }}
                  className={`btn btn-sm ${
                    selectedValue === `${type}_USER_LIST` ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm"
                  }`}
                  id={type}
                  onClick={() => handleUserClick(type)} // Pass type to handler
                >
                  {type.charAt(0) + type.slice(1).toLowerCase()} {/* Format button label */}
                </button>
              ))}
              </div>
            </div>
          </div>

        </div>

        <div
          className="mt-3" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}}
        >
          <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
            <thead className="cardHeader">
            <tr>
                          <th  
                            className="cardHeader"
                           
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              position: "sticky",
                              left:(!(dataLength === 0) && !(dataLength === null)) && -2,
                              zIndex:(!(dataLength === 0) && !(dataLength === null)) &&  2,
                              background:"white"
                            }}
                          >
                            User Details
                            
                          </th>
                          <th
                            className="cardHeader"
                           
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position: "sticky",
                              left: (!(dataLength === 0) && !(dataLength === null)) && 200,
                              zIndex: (!(dataLength === 0) && !(dataLength === null)) && 2,
                              background:"white",
                            }}
                          >
                            Registration Details
                            
                          </th>
                          <th
                            
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                           License Details
                           
                          </th>
                          <th
                            
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Ride Counts
                           
                          </th>
                          <th
                            
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Ride Detail
                            
                          </th>
                          <th
                            
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Mobile Devices
                            
                          </th>
                          <th
                          
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                            }}
                          >
                            Mobile Sessions
                            
                          </th>
                          <th
                           
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Web Sessions
                           
                          </th>
                          
                         

                          <th
                           
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Mobile Exceptions
                           
                          </th>
                          <th
                           
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Mobile Permissions
                           
                          </th>

                          <th
                           
                           style={{
                             fontSize: "13px",
                             fontWeight: 600,
                             padding: "14px",
                             textAlign: "center"
                           }}
                         >
                           Last Push Notification
                          
                         </th>

                          <th
                           
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                            colSpan={2}
                          >
                            Trigger / Actions
                           
                          </th>

                         


                         
                        </tr>
            </thead>

            <tbody>
              {dataLength > 0 ? (
                (summaryDataList || []).map((apiData, index) => (
                  <tr 
                  key={index}
                  >
                    <td
                    style={{
                      fontSize: "13px",
                      verticalAlign: "middle",
                      position: "sticky",
                      left: (!(dataLength === 0) && !(dataLength === null)) && -2,
                      zIndex: (!(dataLength === 0) && !(dataLength === null)) && 2,
                      background: "white",
                    }}
                    >
                      <div
                       className="div-flex"
                      >
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={UserNameIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                                marginLeft: "-2px",
                              }}
                            />
                          </div>
                          
                          <div
                            className="second-div-flex"
                          >                         
                              
                              <span
                                className="span-tag-name"
                              >
                                {apiData.firstName}
                              </span>
                         
                          
                        </div>
                        <div
                          className="div-spacing"
                        >
                          |
                        </div>
                        <div>
                          <p
                            className="p-tag-username"
                          >
                            {apiData.username}
                            </p>
                        </div>
                        <div>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-sm "
                            style={{
                              backgroundColor: "#D7EC23",
                              padding: "3px",
                              fontSize: "8px",
                              fontWeight: 600,
                            }}
                          >
                           {apiData.userStatus}
                          </button>
                        </div>
                      </div>
                      <div
                        className="div-flex-margin"
                      >
                        <div style={{
                            display: "flex",
                            marginLeft: "3px",
                          }}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {apiData.deviceType == "ANDROID" ? (
                              <img
                                src={andIconNew}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              /> 
                            ) : apiData.deviceType == "IOS" ? (
                              <img
                                src={iosIconNew}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "4px",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "11px",
                            }}
                          >
                            {(apiData.deviceType == "ANDROID") || (apiData.deviceType == "IOS" ) ? "Mobile" : (apiData.deviceType == "ANDROID_SDK") || (apiData.deviceType == "IOS_SDK" )  ?  "SDK" : "Other"}
                            
                          </div>
                        </div>
                        
                       
                          
                       
                        <div
                          className="div-spacing"
                        >
                          |
                        </div>
                        <div style={{
                            display: "flex",
                            marginLeft: "3px",
                          }}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={FTwheelerIcon}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "4px",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "11px",
                            }}
                          >
                            {apiData.vehicleType === "FOUR_WHEELER" 
                              ? "4W" 
                              : apiData.vehicleType === "TWO_WHEELER" 
                              ? "2W" 
                              : "-"}
                          </div>
                        </div>
                        
                      </div>

                      <div>

                        <div className="div-flex-reg-act">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={ActType}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                        <p
                            className="p-tag-text-right"
                          >
                            Act Type :
                            </p>
                            <p
                            className="p-tag-text-left"
                          >
                            {capitalizeLetter(apiData.usernameType)}
                            </p>
                        </div>

                        <div className="div-flex-reg-act">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={ActiveDate}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                        <p
                            className="p-tag-text-right"
                          >
                             Act Date :
                            </p>
                            <p
                           className="p-tag-text-left"
                          >
                            {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)} | {formatTime(apiData.createdAtToTimeZone)}
                            </p>
                        </div>
                      </div>


                      
                    
                    </td>

                    <td
                   style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    position:"sticky",
                  left: (!(dataLength === 0) && !(dataLength === null)) && 200,
                  zIndex: (!(dataLength === 0) && !(dataLength === null)) && 2,
                  background:"white"
                  }}
                      
                    >
                     <div>

<div className="div-flex-reg-detail">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={UsernameVerified}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Username Verified:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.usernameVerifiedStatus}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PasswordVerified}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Password Updated:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.passwordUpdatedStatus}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={FirstnameVerified}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    First Name Updated:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.firstNameUpdatedStatus}
    </p>
</div>
</div>
                     
                      
                    </td>

                    <td
                     
                     className="td-container"
                      
                    >
                      <div style={{display:"flex"}}>
<div className="div-flex-reg-act">
 <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LDOrderId}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Order Id:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.licenseOrderId} |
    </p>
</div>

<div className="div-flex-error">
<p
    className="p-tag-text-right"
  >
    {apiData.approvedStatus}
    </p>
    
</div>
</div>
                     

                     

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={ValidityStatus}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Validity:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.validityStatus}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={DownloadStatus}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Download:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.downloadStatus} | {apiData.downloadCount}
    </p>
</div>

<div style={{display:"flex"}}>
<div className="div-flex-reg-act">
 <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LDDeviceId}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Device Id:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.deviceUniqueId}
    </p>
</div>


</div>
<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={ErrorStatus}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Error:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.errorStatus}
    </p>
</div>
                    </td>

                    <td
                     className="td-container"
                    >
                     <div className="div-flex-reg-detail">
                     <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={DriverRides}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Driver:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.driverRideCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PassengerRides}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Passenger:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.passengerRideCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={InvalidRides}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Invalid:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.invalidRideCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={AbnormalRides}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Abnormal:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.abnormalRideCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={DeletedRides}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Deleted:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.deletedRideCount}
    </p>
</div>
                    </td>

                    <td
                      className="td-container"
                    >
                     
                     <div className="div-flex-reg-detail">
                     <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LRName}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Name:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.name}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LRDistance}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Distance:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.totalDistanceInKm}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LRDuration}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Duration:
    </p>
    <p
    className="p-tag-text-left"
  >
    { 
  apiData.totalDurationInMin 
    ? (parseFloat(apiData.totalDurationInMin) < 60 
        ? `${parseFloat(apiData.totalDurationInMin).toFixed(2)} Mins` 
        : `${(parseFloat(apiData.totalDurationInMin) / 60).toFixed(2)} Hours`)
    : "-" 
}

    {/* {apiData.totalDurationInMin} */}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LRCategory}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Category:
    </p>
    <p
    className="p-tag-text-left"
  >
    {capitalizeLetter(apiData.category)}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LRStartTime}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Start Time:
    </p>
    <p
    className="p-tag-text-left"
  >
    {UtilDateTime.formatDateInt(apiData.timeZone)} | {formatTime(apiData.timeZone)} 
    
    </p>
</div>
                    </td>
                    <td
                      className="td-container"
                    >
                      <div style={{display:"flex"}}>
                      <div className="div-flex-reg-detail">
                      <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MDTotal}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Total:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.totalDeviceCount} |
    </p>
</div>
                      <div className="div-flex-reg-detail">
<p
    className="p-tag-text-right"
    style={{marginLeft:"10px"}}
  >
    Active:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.activeDeviceCount}
    </p>
</div>

</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LDDeviceId}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Device Id:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastUniqueId}
    </p>
</div>


<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MDVersion}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Version:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastVersionNumber}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MDModel}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Model:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastModelName}
    </p>
</div>



<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MDRegDate}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Reg Date:
    </p>
    <p
    className="p-tag-text-left"
  >
     {UtilDateTime.formatDateInt(apiData.DeviceLastCreatedAtToTimeZone)} | {formatTime(apiData.DeviceLastCreatedAtToTimeZone)}
    
    </p>
</div>

                      
                      
                     
                      
                    </td>
                    <td
                      className="td-container"
                    >

<div style={{display:"flex"}}>
                      <div className="div-flex-reg-detail">
                      <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MDTotal}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Total:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.totalSessionCount} |
    </p>
</div>
                      <div className="div-flex-reg-detail">
<p
    className="p-tag-text-right"
    style={{marginLeft:"10px"}}
  >
    Active:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.activeSessionCount}
    </p>
</div>

</div>


<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LDDeviceId}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Device Id:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.sessionLastDeviceId}
    </p>
</div>




<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MSAppVersion}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    App Version:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.sessionLastAppVersion}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MSLastSession}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Last Session:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.MobileLastSessionAtToTimeZone)} | {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : formatTime(apiData.MobileLastSessionAtToTimeZone)}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MSLastActivity}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Last Activity:
    </p>
    <p
    className="p-tag-text-left"
  >
     {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.sessionLastActivityAtToTimeZone)} | {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.sessionLastActivityAtToTimeZone)}
   
    </p>
</div>
                      
                     
                     
                    </td>

                    <td
                      className="td-container"
                    >



<div style={{display:"flex"}}>
                      <div className="div-flex-reg-detail">
                      <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={WSTotal}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Total:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.webTotalSessionCount} |
    </p>
</div>
                      <div className="div-flex-reg-detail">
<p
    className="p-tag-text-right"
    style={{marginLeft:"10px"}}
  >
    Active:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.webActiveSessionCount}
    </p>
</div>

</div>




<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LRStartTime}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Token Status:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastTokenStatus}
    </p>
</div>



<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={WSRemoteAdd}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Remote Address:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastRemoteAddress}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={WSLastSession}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Last Session:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.webLastSessionAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.webLastSessionAtToTimeZone)} | {apiData.webLastSessionAtToTimeZone == "-" ? "-" : formatTime(apiData.webLastSessionAtToTimeZone)}
    
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MSLastActivity}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Last Activity:
    </p>
    <p
    className="p-tag-text-left"
  >
     {apiData.lastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.lastActivityAtToTimeZone)} | {apiData.lastActivityAtToTimeZone == "-" ? "-" :  formatTime(apiData.lastActivityAtToTimeZone)}
   
    </p>
</div>
                     
                     
                    </td>

                   

                    

                    <td
                      className="td-container"
                    >

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PreLoginCrash}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Pre Login Crash:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.preLoginCrashExceptionCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PreLoginOthers}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Pre Login Others:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.preLoginOtherExceptionCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PostLoginCrash}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Post Login Crash:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.postLoginCrashExceptionCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PostLoginOthers}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Post Login Others:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.postLoginOtherExceptionCount}
    </p>
</div>

<div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>

<p
    
    style={{textDecoration:"underline"}}
  >
     <Link 
     to="/appExceptionActivities"
     state={{userId:apiData.userId}}
     
     >
     View All
    </Link>
    </p>

</div>







                     
                     
                    </td>


                    <td
                      className="td-container"
                    >

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LoginPermissions}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Login Permission:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.postLoginLoginPermissionCount}
    </p>
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={PeriodicPermissions}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Periodic Permission:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.postLoginPeriodicalPermissionCount}
    </p>
</div>
<div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>

<p
    
    style={{textDecoration:"underline"}}
  >
     <Link 
      to="/appPermissionActivities"
      state={{userId:apiData.userId}}
     >
     View All
    </Link>
    </p>

</div>











                     
                     
                    </td>

                    <td
                      className="td-container"
                    >



<div style={{display:"flex"}}>
                      <div className="div-flex-reg-detail">
                      <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={AppUpdate}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    App Update:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastAppUpdateAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.lastAppUpdateAtToTimeZone)} | {apiData.lastAppUpdateAtToTimeZone == "-" ? "-" : formatTime(apiData.lastAppUpdateAtToTimeZone)}
    </p>
</div>
                     

</div>

<div style={{display:"flex",marginTop:"10px"}}>
                      <div className="div-flex-reg-detail">
                      <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MapUpdated}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    Map Update:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastMapUpdateAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.lastMapUpdateAtToTimeZone)} | {apiData.lastMapUpdateAtToTimeZone == "-" ? "-" : formatTime(apiData.lastMapUpdateAtToTimeZone)}
    </p>
</div>
                      

</div>

<div style={{display:"flex",marginTop:"10px"}}>
                      <div className="div-flex-reg-detail">
                      <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={NoActivity}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
  >
    No Activity:
    </p>
    <p
    className="p-tag-text-left"
  >
    {apiData.lastNoActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.lastNoActivityAtToTimeZone)} | {apiData.lastNoActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.lastNoActivityAtToTimeZone)}
    </p>
</div>
                     

</div>

                    </td>

                    <td
                      className="td-container"
                      style={{borderRight:"0px"}}
                    >
                      <div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={AppUpdatePush}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                          <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_APP_VERSION_PUSH",apiData.userId)}
  >    
  App Update Push
    
    </p> 
{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
     <Link to={"#"}>
    App Update Push
    </Link>
    </p> */}
    
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MapUpdatePush}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_MAP_VERSION_PUSH",apiData.userId)}
  >    
  Map Update Push
    
    </p> 
                         
{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
     <Link to={"#"}>
    Map Update Push
    </Link>
    </p> */}
    
    
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={NoActivityPush}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_NO_ACTIVITY_PUSH",apiData.userId)}
  >    
   No Activity Push
    
    </p> 
                          
{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    No Activity Push
    </Link>
    </p> */}
    
    
</div>

<div className="div-flex-reg-act">
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={WelcomeSMS}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                         <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_USER_APP_SETTINGS_PUSH",apiData.userId)}
  >    
   User App Settings Push
    
    </p> 
                          
{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    User App Settings Push
    </Link>
    </p> */}
    
    
</div>



                    </td>

                    <td
                      className="td-container"
                      style={{borderLeft:"0px"}}
                    >
                      <div style={{display:"flex",justifyContent:'space-between'}}>
                      <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div style={{display:"flex",flexDirection:"column"}}>
                      <div style={{marginTop:"15px",}}>|</div>
                      <div style={{marginTop:"20px",}}>|</div>
                      <div style={{marginTop:"18px",}}>|</div>
                      <div style={{marginTop:"20px",}}>|</div>
                      
                     
                      </div>
                      <div style={{display:"flex",flexDirection:"column", marginLeft:"10px"}}>

                      <div className="div-flex-reg-act">
                     {(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ?   <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={AppUpdateSMS}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_APP_VERSION_SMS",apiData.userId)}
  >    
   App Update SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_APP_VERSION_EMAIL",apiData.userId)}
  >    
    App Update Email
    
    </p> : "-"}


{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "App Update SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "App Update Email" : "-"}
    </Link>
    </p> */}
   
    </> : "-"}
    
</div>

                      <div className="div-flex-reg-act">
{(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ? <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MapUpdateSMS}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_MAP_VERSION_SMS",apiData.userId)}
  >    
    Map Update SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_MAP_VERSION_EMAIL",apiData.userId)}
  >    
    Map Update Email
    
    </p> : "-"}


{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "Map Update SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "Map Update Email" : "-"}
    </Link>
    </p> */}
    
     </> : "-"}
    
</div>




                   
<div className="div-flex-reg-act">
{(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ? <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={NoActivitySMS}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_NO_ACTIVITY_SMS",apiData.userId)}
  >    
    No Activity SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_NO_ACTIVITY_EMAIL",apiData.userId)}
  >    
    No Activity Email
    
    </p> : "-"}

{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "No Activity SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "No Activity Email" : "-"}
    </Link>
    </p>  */}
    
    </> : "-"}
    
</div>



                     
<div className="div-flex-reg-act">
{(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ? <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LoginOTPSms}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_USER_APP_SETTINGS_SMS",apiData.userId)}
  >    
    User App Settings SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_USER_APP_SETTINGS_EMAIL",apiData.userId)}
  >    
    User App Settings Email
    
    </p> : "-"}
{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "User App Settings SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "User App Settings Email" : "-"}
    </Link>
    </p>  */}
    
    </> : "-"}
    
</div>





                      </div>

                      
</div>
<div style={{display:"flex",justifyContent:"space-between",marginLeft:"10px"}}>
                        <div style={{display:"flex",flexDirection:"column"}}>
                      <div style={{marginTop:"15px",}}>|</div>
                      <div style={{marginTop:"20px",}}>|</div>
                      <div style={{marginTop:"18px",}}>|</div>
                      {/* <div style={{marginTop:"20px",}}>|</div> */}
                     
                     
                      </div>
                      <div style={{display:"flex",flexDirection:"column", marginLeft:"10px"}}>

                      <div className="div-flex-reg-act">
                     {(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ?   <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={AppUpdateSMS}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_APP_WELCOME_SMS",apiData.userId)}
  >    
    App Welcome SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_APP_WELCOME_EMAIL",apiData.userId)}
  >    
    App Welcome Email
    
    </p> : "-"}
    </> : "-"}

    
    
</div>

                      <div className="div-flex-reg-act">
{(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ? <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={MapUpdateSMS}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_USER_LOGIN_OTP_SMS",apiData.userId)}
  >    
    User Login OTP SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_USER_LOGIN_OTP_EMAIL",apiData.userId)}
  >    
    User Login OTP Email
    
    </p> : "-"}

{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "User Login OTP SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "User Login OTP Email" : "-"}
    </Link>
    </p> */}
     </> : "-"}
    
</div>




                   
<div className="div-flex-reg-act">
{(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ? <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={Fpwdotp}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>

                          {(apiData.usernameType == "MOBILE_NUMBER") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_FORGOT_PASSWORD_OTP_SMS",apiData.userId)}
  >    
    Forgot Password OTP SMS
    
    </p> : (apiData.usernameType == "EMAIL_ADDRESS") ? <p
    className="p-tag-text-right"
    style={{textDecoration:"underline",color:"#5156BE",cursor:"pointer"}}
    onClick={() => postSuperAdminTriger("SA_V1_FORGOT_PASSWORD_OTP_EMAIL",apiData.userId)}
  >    
    Forgot Password OTP Email
    
    </p> : "-"}

{/* <p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "Forgot Password OTP SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "Forgot Password OTP Email" : "-"}
    </Link>
    </p>  */}
    
    </> : "-"}
    
</div>



                     
{/* <div className="div-flex-reg-act">
{(apiData.usernameType == "MOBILE_NUMBER") || (apiData.usernameType == "EMAIL_ADDRESS") ? <>
<div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={LoginOTPSms}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
<p
    className="p-tag-text-right"
    style={{textDecoration:"underline"}}
  >
    <Link to={"#"}>
    {(apiData.usernameType == "MOBILE_NUMBER") ? "Use Login OTP SMS" : (apiData.usernameType == "EMAIL_ADDRESS") ? "Use Login OTP EMAIL" : "-"}
    </Link>
    </p> </> : "-"}
    
</div> */}



                      </div>

                      
</div>
</div>


                    

                      

                    </td>

                    

                  
                  </tr>
                 ))
              ) : dataLength === 0 ? ( 
                 <tr>
                  {" "}
                  <td colSpan="13">
                    <div
                      style={{
                        color: "red",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                        fontSize:"13px"
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </div>
                  </td>
                </tr>
              ) : dataLength === null ? (
                <tr>
                  {" "}
                  <td colSpan="13">
                    <div
                      style={{
                        color: "green",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )} 
            </tbody>
          </Table>          
        </div>    
        </Col>   
      </Row>

      <Row >
        <Col>
          <div className="d-flex justify-content-between mb-3 mt-3 me-1">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={previous}
              style={{
                visibility: prevShow ? "visible" : "hidden",
                width: "45px",
                float: "left",
              }}
            >
              Prev
            </button>
            {dataLength > 0 ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="pagination ">
                    <a>{pageNumber}</a>
                    {/* <a>10</a>  */}
                  </div>
                  <p
                    style={{
                      marginTop: "6px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    of
                  </p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                    {/* <a>{20}</a> */}
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  onClick={next}
                  style={{
                    visibility: nextShow ? "visible" : "hidden",
                    float: "hidden",
                  }}
                >
                  Next
                </button>
              </>
            ) : null}
          </div>
        </Col>
      </Row>

            {/* <Row className="my-2 py-2">
              <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                <h6 className="mb-0">Current / Subscription Status</h6>
              </Col>
            </Row>

            <Row>
              {(newSADTopRowItemsTemp || []).map((widget, key) => (
                <COL lg={2.4} md={6} key={key} className="px-2">
                  <Card className="card-h-100">
                    <CardBody style={{ padding: "10px" }}>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <span
                            data-tooltip-id={key + "fd"}
                            className="text-muted mb-3 d-block text-truncate fw-semibold"
                          >
                            {widget.title}
                          </span>
                          <h4 className="mb-3">
                            <span className="counter-value">
                              {`${widget.availableLicenseCount} / ${widget.totalLicenseCount}`}
                            </span>
                          </h4>
                          <ReactTooltip
                            id={key + "fd"}
                            place="top"
                            content={widget.tooltips}
                            style={{ backgroundColor: "black" }}
                          />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span className="ms-1 text-muted font-size-13">
                          {widget.bottomTitle}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
             
                </COL>
              ))}
            </Row>

            <Row className="my-2 py-2">
              <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                <h6 className="mb-0">User Onboard Status</h6>
              </Col>
            </Row>
            <Row>
              {(ADTopRowItemsTemp || []).map((widget, key) => (
                <COL lg={2.4} md={6} key={key} className="px-2">
                 
                  <Card className="card-h-100">
                    <CardBody style={{ padding: "10px" }}>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <span
                            data-tooltip-id={key + 111}
                            className="text-muted mb-3 d-block text-truncate fw-semibold"
                          >
                            {widget.title}
                          </span>
                          <h4 className="mb-3">
                    
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={widget.totalCount}
                                decimals={0}
                                decimal="."
                                duration={1}
                              />
                              {widget.postFix}
                            </span>
                          </h4>
                          <ReactTooltip
                            id={key + 111}
                            place="top"
                            content={widget.tooltip}
                            style={{ backgroundColor: "black" }}
                          />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span className="ms-1 text-muted font-size-13">
                          {widget.bottomTitle}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                  
                </COL>
              ))}
            </Row> */}



          </div>
          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setFilter={setFilter}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            filter={filters.durationFilter}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuperAdminAmazonDashboard;
